import { KeyboardArrowDown, KeyboardArrowRight, KeyboardArrowUp } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';

export const createExpanderColumn = <T,>() => {
  const columnHelper = createColumnHelper<T>();

  return columnHelper.display({
    id: 'expander',
    header: '',
    cell: ({ row }) => {
      const isExpandable = row.getCanExpand();
      const isExpanded = row.getIsExpanded();

      return (
        <IconButton
          aria-label='expand row'
          size='small'
          onClick={row.getToggleExpandedHandler()}
          sx={{ marginLeft: row.depth }}
          disabled={!isExpandable}
        >
          <ExpanderIcon isExpandable={isExpandable} isExpanded={isExpanded} />
        </IconButton>
      );
    },
    size: 50
  });
};

const ExpanderIcon = ({ isExpandable, isExpanded }: { isExpandable: boolean; isExpanded: boolean }) => {
  if (!isExpandable) {
    return <KeyboardArrowRight />;
  }

  return isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />;
};
