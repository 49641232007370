import { useMutation } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { queryClient } from 'Lib/react-query';
import { PostOrderDto } from 'Types';

export const useAddOrder = () =>
  useMutation(
    async (data: PostOrderDto) => {
      await apiClient.postOrder(data);
    },
    {
      onSuccess: () => queryClient.invalidateQueries('orders')
    }
  );
