import 'nprogress/nprogress.css';

import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import NProgress from 'nprogress';

import LogoIcon from 'Assets/icons/LogoWithNameIcon';

const FullPageLoader = () => {
  NProgress.configure({ showSpinner: false });

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      position='fixed'
      width='100vw'
      height='100vh'
      top='0'
      left='0'
      zIndex={1000}
      bgcolor='background.default'
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <LogoIcon sx={{ width: 200, height: 200 }} />
    </Box>
  );
};

export default FullPageLoader;
