import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { CheckBox } from 'devextreme-react';

interface DXCheckboxProps {
  name: string;
  label: string;
}

export const DXCheckbox: FC<DXCheckboxProps> = (props) => {
  const { control } = useFormContext();
  const {
    field: { ref, value, ...fieldProps },
    fieldState: { error, invalid }
  } = useController({ name: props.name, control });

  return (
    <CheckBox
      ref={ref}
      value={value}
      onValueChanged={(e) => fieldProps.onChange(e.value)}
      text={props.label}
      isValid={!invalid}
      validationErrors={[{ message: error?.message }]}
    />
  );
};
