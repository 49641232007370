import {
  Block as BlockIcon,
  Construction as ConstructionIcon,
  Engineering as EngineeringIcon,
  Error as ErrorIcon,
  Pause as PauseIcon,
  Stop as StopIcon,
  TrendingDown as TrendingDownIcon
} from '@mui/icons-material';

import { palette } from 'Theme/colors';

const WorkstationStates = {
  NOT_WORKING: 'NotWorking',
  MICROIDLE: 'Microidle',
  IDLE: 'Idle',
  NORMAL_WORK: 'NormalWork',
  FAILURE: 'Failure',
  WORK_WITH_FAILURE: 'WorkWithFailure',
  RETOOL: 'Retool',
  SUSPENDED_RETOOL: 'SuspendedRetool'
} as const;

export const performanceDropsStatesList = [
  WorkstationStates.IDLE,
  WorkstationStates.FAILURE,
  WorkstationStates.WORK_WITH_FAILURE,
  WorkstationStates.RETOOL
];

export const workstationStatesList = [
  WorkstationStates.NOT_WORKING,
  WorkstationStates.MICROIDLE,
  WorkstationStates.IDLE,
  WorkstationStates.NORMAL_WORK,
  WorkstationStates.FAILURE,
  WorkstationStates.WORK_WITH_FAILURE,
  WorkstationStates.RETOOL,
  WorkstationStates.SUSPENDED_RETOOL
];

export type WorkstationStatesKeys = keyof typeof WorkstationStates;
export type WorkstationState = (typeof WorkstationStates)[WorkstationStatesKeys];

export type PerformanceDropsStatesKeys = (typeof performanceDropsStatesList)[number];

export const WorkstationStatesTranslationKeys = {
  [WorkstationStates.FAILURE as string]: 'labels.failure',
  [WorkstationStates.IDLE as string]: 'labels.idle',
  [WorkstationStates.MICROIDLE as string]: 'labels.microidle',
  [WorkstationStates.NORMAL_WORK as string]: 'labels.normalWork',
  [WorkstationStates.NOT_WORKING as string]: 'labels.notWorking',
  [WorkstationStates.RETOOL as string]: 'labels.retool',
  [WorkstationStates.WORK_WITH_FAILURE as string]: 'labels.workWithFailure',
  [WorkstationStates.SUSPENDED_RETOOL as string]: 'labels.suspendedRetool'
} as const;

export const WorkstationStateShortTranslationKeys = {
  ...WorkstationStatesTranslationKeys,
  [WorkstationStates.SUSPENDED_RETOOL as string]: 'labels.suspended_retool'
} as const;

export const WorkstationStateIcons = {
  [WorkstationStates.NOT_WORKING]: StopIcon,
  [WorkstationStates.MICROIDLE]: PauseIcon,
  [WorkstationStates.IDLE]: PauseIcon,
  [WorkstationStates.NORMAL_WORK]: EngineeringIcon,
  [WorkstationStates.FAILURE]: ErrorIcon,
  [WorkstationStates.WORK_WITH_FAILURE]: TrendingDownIcon,
  [WorkstationStates.RETOOL]: ConstructionIcon,
  [WorkstationStates.SUSPENDED_RETOOL]: BlockIcon
} as const;

export const WorkstationStateColors = {
  [WorkstationStates.NOT_WORKING]: palette.status.notWorking,
  [WorkstationStates.MICROIDLE]: palette.status.microidle,
  [WorkstationStates.IDLE]: palette.status.idle,
  [WorkstationStates.NORMAL_WORK]: palette.status.normalWork,
  [WorkstationStates.FAILURE]: palette.status.failure,
  [WorkstationStates.WORK_WITH_FAILURE]: palette.status.workWithFailure,
  [WorkstationStates.RETOOL]: palette.status.retool,
  [WorkstationStates.SUSPENDED_RETOOL]: palette.status.suspendedRetool
} as const;

export default WorkstationStates;
