import React from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Checkbox, FormControlLabel, IconButton, MenuItem, Popover, styled, Typography } from '@mui/material';

import Tooltip from 'Components/Tooltip';
import { OrderStatus, OrderStatusesTranslationKeys } from 'Consts/OrderStatuses';
import { Option, StatusFilterOption } from 'Types';

import { getColor } from './utils';

const Container = styled('div')(({ marginLeft, marginTop }: { marginLeft?: string | number; marginTop?: number }) => ({
  marginLeft,
  marginTop,
  width: 24,
  height: 13,
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap-reverse',
  alignItems: 'stretch'
}));

const Circle = styled('div')({
  height: 6,
  width: 6,
  borderRadius: '50%',
  marginRight: 2,
  display: 'flex'
});

const CirclePreview = styled('div')(({ color }: { color: string }) => ({
  height: 16,
  width: 16,
  borderRadius: '50%',
  display: 'flex',
  backgroundColor: color,
  marginLeft: '0.5rem',
  marginRight: '1rem'
}));

const getLabel = (name: string) => (
  <Typography fontSize='12px'>{getI18n().t(OrderStatusesTranslationKeys[name])}</Typography>
);

export type StatusFilterProps = {
  options?: StatusFilterOption[];
  value?: Option[];
  onChange: (statuses: Option[]) => void;
  menuItemHeight: string;
};

const StatusFilterComponent: React.FC<StatusFilterProps> = ({ options = [], value = [], onChange, menuItemHeight }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const allChecked = value.length === options.length;
  const { t } = useTranslation();

  const handleToggle = (v: StatusFilterOption) => {
    const newSelected = [...value];
    const currentIndex = newSelected.map((s) => s.id).indexOf(v.id);

    if (currentIndex === -1) {
      newSelected.push(v);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    onChange(newSelected);
  };

  const handleToggleAll = () => {
    if (options.length === value.length) {
      onChange([]);
    } else {
      onChange(options);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getTitle = () =>
    allChecked ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '0.5rem',
          alignItems: 'center'
        }}
      >
        <Container>
          {options.map((o) => (
            <Circle key={`title-circle-${o.id}`} style={{ backgroundColor: o.color }} />
          ))}
        </Container>
        <Typography fontSize='12px' style={{ marginLeft: '0.5rem' }}>
          {t('labels.allStatuses')}
        </Typography>
      </div>
    ) : (
      value.map((o) => (
        <div
          key={`title-div-${o.id}`}
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '0.5rem',
            alignItems: 'center'
          }}
        >
          <Circle style={{ backgroundColor: getColor(o.name as OrderStatus) }} />
          <Typography fontSize='12px' style={{ marginLeft: '0.5rem' }}>
            {t(OrderStatusesTranslationKeys[o.name])}
          </Typography>
        </div>
      ))
    );

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={value?.length ? getTitle() : ''}>
          <Container marginLeft={16} marginTop={6}>
            {value.map((o) => (
              <Circle key={`tooltip-circle-${o.id}`} style={{ backgroundColor: getColor(o.name as OrderStatus) }} />
            ))}
          </Container>
        </Tooltip>
        <IconButton onClick={handleClick}>
          <ArrowDropDownIcon />
        </IconButton>
      </Box>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -100
        }}
      >
        <MenuItem value='' onChange={handleToggleAll} key='select-all' style={{ height: menuItemHeight }}>
          <FormControlLabel
            control={
              <>
                <Checkbox checked={allChecked} />
                <Container marginLeft='0.3rem'>
                  {options.map((o) => (
                    <Circle key={o.id} style={{ backgroundColor: o.color }} />
                  ))}
                </Container>
              </>
            }
            label={
              <Typography fontSize='12px' style={{ marginLeft: '0.7rem', fontWeight: '700' }}>
                {t('labels.showAll')}
              </Typography>
            }
          />
        </MenuItem>
        {options.map((o) => (
          <MenuItem
            key={`multiselect-item-${o.id}`}
            onChange={() => {
              handleToggle(o);
            }}
            style={{ height: menuItemHeight }}
          >
            <FormControlLabel
              style={{ width: '100%' }}
              control={
                <>
                  <Checkbox checked={!!value?.find((v) => v.id === o.id) || allChecked} />
                  <CirclePreview color={o.color} />
                </>
              }
              label={getLabel(o.name)}
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default StatusFilterComponent;
