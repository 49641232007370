import { useQuery } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { GetOrderDto } from 'Types';

export const useOrderQuery = (
  id: number,
  queryParams: {
    enabled: boolean;
    onSuccess?: (data: GetOrderDto) => void;
    onError?: (error: unknown) => void;
  }
) => useQuery(['order', id], async () => (await apiClient.getOrder(id)).data, queryParams);
