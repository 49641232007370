import React from 'react';
import { Box } from '@mui/material';

const PageHeader: React.FC = ({ children }) => (
  <Box display='flex' justifyContent='space-between' alignItems='center' py={2.5}>
    {children}
  </Box>
);

export default PageHeader;
