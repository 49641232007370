import TaskCalendarItemTypes, { type TaskCalendarItemType } from 'Consts/CalendarTaskTypes';
import { MachineMode } from 'Consts/MachineModes';
import { PermissionName } from 'Consts/Permissions';
import { type TaskStatus } from 'Consts/TaskStatuses';
import { type WorkstationState } from 'Consts/WorkstationStates';

import { FileType } from './Files';
import {
  IdleEndedMessage,
  IdleWasSetOnWorkstationMessage,
  MicroidleEndedMessage,
  MicroidleSetMessage,
  StartedFailureMessage,
  StartedIdleMessage,
  StartedNormalWorkMessage,
  StartedRetoolMessage,
  StartedWorkWithFailureMessage,
  SuspendedRetoolEndedMessage,
  SuspendedRetoolStartedMessage,
  WorkstationStartedWorkingMessage,
  WorkstationStoppedWorkingMessage
} from './HubMessages';
import { CatalogCategoryTranslation, ReasonDescriptionDto } from './shared';

export type { PermissionName } from 'Consts/Permissions';
export * from './AuthorizationTypes';
export * from './Calendars';
export * from './Catalogs';
export * from './Configuration';
export * from './DataLoggerParams';
export * from './CheckLists';
export * from './Files';
export * from './ForemanArrivalRequest';
export * from './ForemanReports';
export * from './HeartBeats';
export * from './HubMessages';
export * from './Incidents';
export * from './LineLayouts';
export * from './Machines';
export * from './MachineStates';
export * from './MaintenancePlanner';
export * from './MaintenanceTasks';
export * from './ShiftWorkstation';
export * from './Technologies';
export * from './Translations';
export * from './Notifications';
export * from './Orders';
export * from './PairedMachineConnections';
export * from './PermissionDefinitions';
export * from './ShiftConfiguration';
export * from './Tasks';
export * from './Shifts';
export * from './WorkstationFailureReports';
export * from './Reports';
export * from './ProductionLines';
export * from './shared';
export * from './Users';
export * from './Variants';
export * from './Roles';
export * from './Kanban';
export * from './Components';
export * from './WorkstationProduction';

export type Status = {
  text?: string;
  type: WorkstationState;
  durationInSeconds?: number;
  beginsAt: string;
};

export enum DashboardType {
  Normal = 0,
  Start = 1,
  Middle = 2,
  End = 3,
  QualityControl = 4,
  Simple = 5
}

export const lineModeDashboardTypes = [
  DashboardType.Start,
  DashboardType.Middle,
  DashboardType.End,
  DashboardType.QualityControl,
  DashboardType.Simple
];

export type Machine = {
  active: boolean;
  actualUnitProductionCount: number;
  defaultUnitProductionCount: number;
  id: string;
  name: string;
  productionLineIds: string[];
  removed?: boolean;
  dashboardType: DashboardType;
  isFastLoginEnabled: boolean;
  isAutoLogoutOnOtherRfidCardScanEnabled: boolean;
};

export type SnapshotBase = {
  creationDate?: string | null;
  lastBunchProductionDate?: string | null;
  lastRetoolDate?: string | null;
  reworkAvailable?: boolean;
  shiftInvalidComponentsCount?: number | null;
  hourlyInvalidComponentsCount?: number | null;
  cancelledBunchId?: string;
  cancelledUnitCount?: number | null;
};

export type ContinousModeSnapshot = SnapshotBase & {
  workingMode?: 'Serial';
  hourlyGoal?: number | null;
  hourlyPacedGoal?: number | null;
  hourlyInvalidUnitsCount?: number;
  shiftInvalidUnitsCount?: number;
  reworksHourlyCount?: number;
  reworksShiftCount?: number;
  shiftGoal?: number | null;
  shiftPacedGoal?: number | null;
  hourlyValidUnitsCount?: number;
  shiftValidUnitsCount?: number;
  shiftInvalidComponentsCount?: number | null;
  hourlyInvalidComponentsCount?: number | null;
};

export type OrderModeSnapshot = SnapshotBase & {
  workingMode?: 'Order';
  lastComponentBunchProductionDate?: string | null;
  orderComponentValidUnitsCount?: number;
  orderComponentGoal?: number | null;
  orderComponentMachineValidUnitsCount?: number;
  orderComponentMachineInvalidUnitsCount?: number;
  orderComponentMachineReworkCount?: number;
  orderComponentMachineActualGoal?: number | null;
  orderComponentMachinePacedActualGoal?: number | null;
  orderComponentMachineInvalidComponentsCount: number | null;
  orderComponentMachineProductPlannedEndDate: string | null;
};

export type Snapshot = ContinousModeSnapshot | OrderModeSnapshot;

export type WorkstationStatusChangedMessage =
  | StartedNormalWorkMessage
  | StartedWorkWithFailureMessage
  | StartedFailureMessage
  | StartedIdleMessage
  | IdleWasSetOnWorkstationMessage
  | MicroidleSetMessage
  | IdleEndedMessage
  | MicroidleEndedMessage
  | WorkstationStartedWorkingMessage
  | WorkstationStoppedWorkingMessage
  | StartedRetoolMessage
  | SuspendedRetoolStartedMessage
  | SuspendedRetoolEndedMessage;

export type MachineBaseType = {
  name: string;
  id: string;
};

export type WorkstationsType = { name: string; id: string }[];

export type ProductionLineType = {
  id: string;
  name: string;
  isRemovable: boolean;
  machines: WorkstationsType;
};

export type StatusFilterOption = {
  color: string;
  id: string;
  name: string;
};

export type OeeType = {
  availability: number | null;
  performance: number | null;
  quality: number | null;
  technicalAvailability: number | null;
  value: number | null;
  calculatedTo: string;
};

export type StatusesDistribution = { [key in WorkstationState]?: number };

export type LineStatusWorkstationType = {
  machineId: string;
  machineName: string;
  machineMode: MachineMode;
  workingMode: MachineMode;
  machineIsOccupied: boolean;
  dataLoggerOnline: boolean;
  shiftProductionGoal: number | null;
  shiftProductionPacedGoal: number | null;
  statusCode: WorkstationState;
  statusDescription?: ReasonDescriptionDto | null;
  statusDurationInSeconds: number;
  statusesDistribution: StatusesDistribution;
  oee: OeeType | null;
  validProducedUnitCount: number | null;
  invalidProducedUnitCount: number | null;
  changedTime: Date;
  enforceReasonsClassification: boolean;
  variantName: string | null;
  orderName: string | null;
  userName: string | null;
};

export type StatesDurationPercentageType = Partial<Record<WorkstationState, number>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyType = any; // Shouldn't be done like this. In fact, "any" shouldn't be used at all. To refactor.

export type SelectDataType = {
  id: string;
  name: string;
};

// #region ProductionPlan
export type ProductionPlanChartDataSliceDto = {
  oee: OeeType | null;
  producedUnits: number | null;
  invalidProducedUnits: number;
  reworkCount: number;
  dateFrom: string;
  dateTo: string;
  productionCurrentGoal: number | null;
  productionCurrentPacedGoal: number | null;
  dominantStatus?: WorkstationState;
  dominantVariantName?: string;
  dominantOrderNumber?: string;
  statesDurationPercentage?: StatesDurationPercentageType | null;
};
export type ProductionPlanChartDataDto = {
  from: string;
  to: string;
  slices: ProductionPlanChartDataSliceDto[] | null;
  wasProduction: boolean;
  timeRangeGoal?: number | null;
  timeRangePacedGoal?: number | null;
};
export type ProductionPlanForIntervalsChartDataDto = {
  from: string;
  to: string;
  slices: ProductionPlanChartDataSliceDto[] | null;
  wasProduction: boolean;
};
// #endregion

export type FailureReportDetailsDto = {
  id: string;
  workstationId: string;
  machineName: string;
  productionLineName: string | null;
  failureStartDate: string;
  reporter: string;
  type: 'WorkstationFailure' | 'WorkstationWorkWithFailure';
  reasonDescription?: ReasonDescriptionDto;
  checkListCategories: FailureDetailsCheckListCategoryType[];
};

type MechanicTaskDetailsBase = {
  id: string;
  mechanicId: string;
  mechanicName: string;
  machineId: string;
  machineName: string;
  state: TaskStatus;
  startDate: string;
  endDate: string;
  startedAt?: string | null;
  finishedAt?: string | null;
  workTimeInSeconds: number;
  fixDurationInSeconds: number;
  creationDate: string;
  stopReason: string | null;
  stopTimeInSeconds: number | null;
  taskReason?: ReasonDescriptionDto | null;
  checkListCategories: FailureDetailsCheckListCategoryType[];
};

export type MechanicMaintenanceTaskDetailsDto = MechanicTaskDetailsBase & {
  type: typeof TaskCalendarItemTypes.MAINTENANCE_TASK;
  maintenanceTaskId: string;
};

export type MechanicRepairTaskDetailsDto = MechanicTaskDetailsBase & {
  type: typeof TaskCalendarItemTypes.FAILURE_REPAIR_TASK | typeof TaskCalendarItemTypes.WORK_WITH_FAILURE_REPAIR_TASK;
  reporter: string;
  machineFailure: ReasonDescriptionDto;
  workstationFailureReportDate?: string | null;
};

/** api/tasking/Tasks/${id} */
export type MechanicTaskDetailsDto = MechanicMaintenanceTaskDetailsDto | MechanicRepairTaskDetailsDto;

export type CalendarType = {
  taskId: string;
  startDate: string;
  duration: string;
  type: TaskCalendarItemType;
  endDate: string;
  machineName: string;
  state: TaskStatus;
  index?: number;
  collidingIds?: string[];
  itemId: string;
  slotsToNextItem?: number;
};

export type TimeBlockType = {
  date: Date;
  endDate: Date;
  items: CalendarType[];
  slotsCount: number;
};

export type CalendarUserType = 'Mechanic' | 'MechanicLeader';

export type ApiCodedErrorType = {
  Code: string;
  Message: string;
  Type: string;
  PropertyName?: string;
};

export type ApiBaseErrorType = ApiCodedErrorType & {
  CorrelationId: string;
  ErrorId: string;
  StatusCode: number;
};

export type ValidationError = ApiBaseErrorType & {
  Errors: ApiCodedErrorType[];
};

export type ApiError = ApiBaseErrorType | ValidationError;

export type CheckListSteps = {
  checkListId: string;
  stepsTaken: string[];
};

export type CatalogFormCategoryReason = {
  reasonId?: number;
  temporaryId?: number;
  position: number;
  translations: Record<string, { name: string; code: string }>;
  isTechnical: boolean | null;
} & Icon;

export type Icon = {
  icon?: FileType | null;
  iconUrl?: string | null;
};

export type CatalogFormCategory = {
  position: number;
  translations: Record<string, string>;
  reasons: CatalogFormCategoryReason[];
  categoryId?: string;
  temporaryId?: string;
} & Icon;

export type CatalogFormValues = {
  typeId: number;
  name: string;
  languagesCodes: string[];
  defaultLanguageCode: string;
  categories: CatalogFormCategory[];
  machines: { id: string; name: string }[];
};

export type CalendarFilterType = 'Today' | 'Tomorrow' | 'WholeWeek';

export type CheckListCategoryReasonType = {
  reasonId?: string;
  descriptions: Record<string, string>;
  position: number;
  index?: number;
  categoryIndex?: number;
  uniqueId: string;
};

export type CheckListCategoryReasonTypeDto = Omit<CheckListCategoryReasonType, 'reasonId'> & { reasonId: string };

export type CheckListCategoryType = {
  categoryId?: string;
  reasons: CheckListCategoryReasonType[];
  position: number;
  names: Record<string, string>;
  index?: number;
};

export type FailureDetailsCheckListCategoryReasonType = {
  checked: boolean;
  descriptions: CatalogCategoryTranslation;
  reasonId: string;
};

export type FailureDetailsCheckListCategoryType = {
  names: CatalogCategoryTranslation;
  reasons: FailureDetailsCheckListCategoryReasonType[];
  categoryId: string;
};

export type CheckListBaseType = {
  id?: string;
  name: string;
  categories: CheckListCategoryType[];
  languagesCodes: string[];
  defaultLanguageCode: string;
};

export type CheckListType = CheckListBaseType & {
  machines: { machineId: string; name: string }[];
};

export type ClassNameMap<ClassKey extends string = string> = Record<ClassKey, string>;

export type AppUser = {
  uniqueId: string;
  defaultLanguageCode: string;
  expiring: number;
  permissions: Set<PermissionName>;
  loginMethod: 'credentials' | 'rfid';
  rfid?: string;
  role?: 'SuperAdmin';
  userName: string;
};

export type InvalidBunch = {
  reasonId?: number;
  reasonDescription?: string;
  unitCount: number;
  barCode?: string;
};

export type PotentialNOK = {
  unitsCount: number;
  productionDate: string;
};

export type OrderStartedBarcode = {
  triggerWorkstationId: string;
  orderId: number;
  isStarted: boolean;
};

type InformationToastType = 'success' | 'error';

export interface InformationToastState {
  isVisible: boolean;
  message: string;
  type: InformationToastType;
}
