import { useQuery } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { GetSimpleOrdersApiParams, GetSimplePanelOrdersApiResponse } from 'Types/Orders';

export const useOrderSimplePanel = (
  { from, to, workstationId }: GetSimpleOrdersApiParams,
  queryParams: {
    enabled: boolean;
    onSuccess?: (data: GetSimplePanelOrdersApiResponse) => void;
  }
) =>
  useQuery(
    ['order-simple-panel', from, to, workstationId],
    async () => (await apiClient.getSimplePanelOrders({ from, to, workstationId })).data,
    queryParams
  );
