import Axios from 'axios';
import Config from 'config';
import qs from 'qs';

import { AdditionalInfo } from 'Hooks/onPremiseLogs/logTypes';

/**
 * Creates an Axios instance configured for logging.
 *
 * This Axios instance is pre-configured with default settings to interact with the API.
 * It includes the following configurations:
 * - `baseURL`: The base URL of the API, provided by `Config.API_DEFAULT_URL`.
 * - `timeout`: The timeout duration for the API requests, set by `Config.API_TIMEOUT`.
 * - `withCredentials`: A boolean that indicates whether or not cross-site Access-Control requests should be made using credentials.
 * - `paramsSerializer`: A custom serializer for query params. It serializes arrays to repeat format using 'qs.stringify'.
 *
 * @type {AxiosInstance}
 */
const logAxiosInstance = Axios.create({
  baseURL: Config.API_DEFAULT_URL,
  timeout: Config.API_TIMEOUT,
  withCredentials: true,
  paramsSerializer: {
    serialize(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  }
});

/**
 * Sends a log message to the backend server.
 *
 * @param {'error' | 'info'} logType - The type of log, either 'error' or 'info'.
 * @param {string} message - The log message to be sent.
 * @param {AdditionalInfo} [additionalInfo={}] - Optional additional information to be included with the log.
 * @returns {Promise<void>} A promise that resolves when the log has been sent.
 * @throws Will throw an error if the log fails to send.
 */
const sendLogToBackend = async (
  logType: 'error' | 'info',
  message: string,
  additionalInfo: AdditionalInfo = {}
): Promise<void> => {
  try {
    await logAxiosInstance.post(`production/onPremiseLog/${logType}`, {
      message,
      additionalInfo
    });
  } catch (err) {
    console.error(`Failed to send ${logType} log to backend`, err);
  }
};

/**
 * Logs an error message along with additional information to the backend.
 *
 * @param {Error} error - The error object containing the error message to be logged.
 * @param {AdditionalInfo} additionalInfo - An optional parameter containing extra information related to the error.
 */
export const logErrorToBackend = (error: Error, additionalInfo: AdditionalInfo = {}) => {
  sendLogToBackend('error', error.message || error.toString(), additionalInfo);
};

/**
 * Sends an informational log message to the backend logging system.
 *
 * @param {string} message - The informational message to be logged.
 * @param {AdditionalInfo} [additionalInfo={}] - Optional object containing additional details to be logged.
 */
export const logInfoToBackend = (message: string, additionalInfo: AdditionalInfo = {}) => {
  sendLogToBackend('info', message, additionalInfo);
};
