import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { DEFAULT_LANGUAGE_CODE } from 'Consts/LocaleLanguageCodes';
import { LocalStorageKeys } from 'Consts/LocalStorageKeys';
import { useUser as useUserContext } from 'Context/UserContext';
import useActiveTranslationsOfLanguages from 'Hooks/translations/useActiveTranslationsOfLanguages';
import useTranslationValues from 'Hooks/translations/useTranslationValues';
import usePersistedState from 'Hooks/usePersistedState';
import FullPageLoader from 'Layout/FullPageLoader';
import { replaceTranslation } from 'Lib/i18n';
import { ActiveTranslationDto } from 'Types/Translations';

interface TranslationsContext {
  selectedLanguageCode: string;
  setSelectedLanguageCode: React.Dispatch<React.SetStateAction<string>>;
  isTranslationLoading: boolean;
}

const TranslationsContext = createContext<TranslationsContext | undefined>(undefined);

export const useTranslationsContext = (): TranslationsContext => {
  const context = useContext(TranslationsContext);

  if (context === undefined) {
    throw new Error('useTranslationsContext must be used within a TranslationsContextProvider');
  }

  return context;
};

const getFallbackTranslationCode = ({
  userDefaultLanguageCode,
  translationsOfLanguages
}: {
  userDefaultLanguageCode: string;
  translationsOfLanguages?: ActiveTranslationDto[];
}) => {
  const isUserLanguageAvailable = !!translationsOfLanguages?.find((t) => t.code === userDefaultLanguageCode);

  if (isUserLanguageAvailable) {
    return userDefaultLanguageCode;
  }

  const browserLanguageCode = navigator.languages[0]?.toLowerCase();
  const browserLanguageTranslation = translationsOfLanguages?.find((t) => {
    return t.code === browserLanguageCode || t.code.split('-')[0] === browserLanguageCode;
  });

  if (browserLanguageTranslation) {
    return browserLanguageTranslation.code;
  }

  return DEFAULT_LANGUAGE_CODE;
};

export const TranslationsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedLanguageCode, setSelectedLanguageCode] = usePersistedState(
    '',
    LocalStorageKeys.SELECTED_LANGUAGE_CODE
  );
  const { user } = useUserContext();
  const userDefaultLanguageCode = user?.defaultLanguageCode ?? DEFAULT_LANGUAGE_CODE;

  const { data: translationsOfLanguages, isLoading: isLoadingTranslationsOfLanguages } =
    useActiveTranslationsOfLanguages();
  const isPersistedLanguageAvailable =
    !!translationsOfLanguages?.find((t) => t.code === selectedLanguageCode) ||
    selectedLanguageCode === DEFAULT_LANGUAGE_CODE;
  const {
    data: translation,
    isLoading: isTranslationLoading,
    isError: isLoadingTranslationError
  } = useTranslationValues(selectedLanguageCode, {
    enabled: isPersistedLanguageAvailable
  });

  useEffect(() => {
    if (translation) {
      replaceTranslation(translation);
    }
  }, [translation]);

  useEffect(() => {
    if (!isLoadingTranslationsOfLanguages && !isPersistedLanguageAvailable) {
      setSelectedLanguageCode(getFallbackTranslationCode({ userDefaultLanguageCode, translationsOfLanguages }));
    }
  }, [
    translationsOfLanguages,
    userDefaultLanguageCode,
    isLoadingTranslationsOfLanguages,
    isPersistedLanguageAvailable
  ]);

  const value: TranslationsContext = useMemo(
    () => ({ selectedLanguageCode, setSelectedLanguageCode, isTranslationLoading }),
    [selectedLanguageCode, isTranslationLoading]
  );

  const showFullPageLoader = !isLoadingTranslationError
    ? !translation
    : isLoadingTranslationsOfLanguages || isTranslationLoading;

  return (
    <>
      {showFullPageLoader && <FullPageLoader />}
      <TranslationsContext.Provider value={value}>{children}</TranslationsContext.Provider>
    </>
  );
};

export const TranslationsContextProviderMock: React.FC<{ value?: TranslationsContext }> = ({ children, value }) => {
  const [selectedLanguageCode, setSelectedLanguageCode] = useState('');

  const defaultValue: TranslationsContext = useMemo(
    () => ({
      selectedLanguageCode,
      setSelectedLanguageCode,
      isTranslationLoading: false
    }),
    [selectedLanguageCode]
  );

  return <TranslationsContext.Provider value={value ?? defaultValue}>{children}</TranslationsContext.Provider>;
};
