import React from 'react';
import { Box } from '@mui/material';

const PageMainContent: React.FC = ({ children }) => (
  <Box flex={1} minHeight={0} display='flex' flexDirection='column'>
    {children}
  </Box>
);

export default PageMainContent;
