import React, { useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog } from '@mui/material';
import { nanoid } from 'nanoid';

import { DialogActions, DialogContent, DialogTitle } from 'Components/Dialog';
import { errorCodes } from 'Consts/Errors';
import getErrorCode from 'Helpers/ApiErrorCodeGetter';
import useAddTechnology from 'Hooks/technologies/useAddTechnology';
import { useVariantsWithMachinesQuery } from 'Hooks/variants/useVariantsWithMachinesQuery';

import { mapTechnologyFormValuesToPostTechnologyDto } from './TechnologyForm/helpers';
import { TechnologyForm } from './TechnologyForm/TechnologyForm';
import { getTechnologFormSchema, TechnologyFormValues } from './TechnologyForm/validation';

type Props = {
  open: boolean;
  onClose: () => void;
};

const AddDialog: React.FC<Props> = ({ open, onClose }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | null>();

  const { data: variantsWithMachines, isLoading: isVariantsWithMachinesLoading } = useVariantsWithMachinesQuery({
    ids: [],
    isDeleted: false
  });
  const variantsWithoutMachinesIds = variantsWithMachines?.filter((v) => !v.machines.length).map((v) => v.id) ?? [];
  const technologyFormSchema = useMemo(
    () => getTechnologFormSchema({ variantsWithoutMachinesIds }),
    [variantsWithoutMachinesIds]
  );

  const formMethods = useForm<TechnologyFormValues>({
    mode: 'all',
    resolver: zodResolver(technologyFormSchema),
    defaultValues: {
      name: '',
      isLineMode: false,
      components: [{ variantId: '', amount: 1, machines: [], tempId: nanoid() }]
    }
  });
  const {
    handleSubmit,
    setError,
    formState: { isValid, isSubmitting }
  } = formMethods;

  const { mutateAsync: addTechnology } = useAddTechnology();

  const onSubmit = async (technology: TechnologyFormValues) => {
    await addTechnology(mapTechnologyFormValuesToPostTechnologyDto(technology), {
      onSuccess: onClose,
      onError: (error: unknown) => {
        const errorCode = getErrorCode(error);
        if (errorCode === errorCodes.technologyNameNotUnique) {
          setError('name', { message: t('messages.valueMustBeUnique') });
        } else {
          setSubmitErrorMessage(t('messages.sorryThereWasAProblemWithYourRequest'));
        }
      }
    });
  };

  if (isVariantsWithMachinesLoading) return <div />;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{ sx: { minWidth: 'min(60rem, 90vw)', height: 'min(45rem, 85vh)' } }}
          disablePortal
        >
          <DialogTitle onClose={onClose}>{t('labels.addNewTechnology')}</DialogTitle>
          <DialogContent ref={contentRef}>
            <TechnologyForm variantsWithMachines={variantsWithMachines ?? []} containerRef={contentRef} />
          </DialogContent>
          <DialogActions actionsVariant='center' errorMessage={submitErrorMessage}>
            <Button onClick={onClose} variant='text'>
              {t('labels.cancel')}
            </Button>
            <Button type='submit' disabled={!isValid || isSubmitting || !!submitErrorMessage}>
              {t('labels.add')}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
};

export default AddDialog;
