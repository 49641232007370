import React, { createContext, useContext } from 'react';

import { useGetFeatureFlagsQuery } from 'Hooks/featureFlags/useGetFeatureFlagsQuery';
import { FeatureFlags } from 'Types';

const defaultFeatureFlags: FeatureFlags = {
  MechanicCanSelfAssignToTask: false,
  OrderImportEnabled: false
};

type FeatureFlagsContext = FeatureFlags;

const FeatureFlagsContext = createContext<FeatureFlagsContext>({ ...defaultFeatureFlags });

export function useFeatureFlagsContext() {
  const context = useContext(FeatureFlagsContext);
  if (context === undefined) {
    throw new Error('useFeatureFlagsContext must be within FeatureFlagsProvider');
  }

  return context;
}

export const FeatureFlagsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: featureFlags } = useGetFeatureFlagsQuery({ retry: true, staleTime: Infinity });

  const value: FeatureFlags = featureFlags ?? defaultFeatureFlags;

  return <FeatureFlagsContext.Provider value={value}>{children}</FeatureFlagsContext.Provider>;
};
