import { useMutation } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { queryClient } from 'Lib/react-query';
import { AnyType } from 'Types';

const useMarkAsReadFile = (machineId: string) =>
  useMutation(
    async (fileId: string) => {
      await apiClient.postFileAsAcknowledged(fileId, machineId);
    },
    {
      onSuccess: () => queryClient.invalidateQueries('files-to-acknowledge'),
      onError: (apiError: AnyType) => {
        const errorCode = apiError?.response?.status;

        if (errorCode === 404) {
          queryClient.invalidateQueries('files-to-acknowledge');
        }
      }
    }
  );

export default useMarkAsReadFile;
