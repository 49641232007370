import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { endOfDay, isValid, startOfDay } from 'date-fns';
import { ValueChangedEvent } from 'devextreme/ui/date_range_box';
import { DateRangeBox } from 'devextreme-react/date-range-box';

interface DXDateRangeProps {
  selectedDateRange: [Date, Date];
  onDateRangeChange: ([startDate, endDate]: [Date, Date]) => void;
  minDate?: Date;
  maxDate?: Date;
  height?: number;
}

export const DXDateRange: FC<DXDateRangeProps> = (props) => {
  const { t } = useTranslation();
  const minDate = startOfDay(props.minDate ?? new Date(new Date().setFullYear(new Date().getFullYear() - 1)));
  const maxDate = endOfDay(props.maxDate ?? new Date(new Date().setFullYear(new Date().getFullYear() + 1)));

  const handleDateRangeChange = (e: ValueChangedEvent) => {
    const [startDate, endDate] = e.value;
    if (startDate && isValid(startDate) && endDate && isValid(endDate)) {
      props.onDateRangeChange([startOfDay(startDate), endOfDay(endDate)]);
    }
  };

  return (
    <DateRangeBox
      validationMessagePosition='bottom'
      invalidStartDateMessage={t('messages.invalidStartDateMessage')}
      invalidEndDateMessage={t('messages.invalidEndDateMessage')}
      endDateOutOfRangeMessage={t('messages.endDateOutOfRangeMessage')}
      startDateOutOfRangeMessage={t('messages.startDateOutOfRangeMessage')}
      startDateLabel={t('labels.startDate')}
      endDateLabel={t('labels.endDate')}
      applyValueMode='useButtons'
      openOnFieldClick={false}
      value={props.selectedDateRange}
      onValueChanged={handleDateRangeChange}
      min={minDate}
      max={maxDate}
      height={props.height}
    />
  );
};
