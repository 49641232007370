import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { type OrderExecutor } from 'Consts/executors';
import { LocalStorageKeys } from 'Consts/LocalStorageKeys';
import type { MachineMode } from 'Consts/MachineModes';
import type { VariantMode } from 'Consts/VariantModes';
import paths from 'Routes/paths';
import { Machine } from 'Types';

export type CurrentOrder = {
  name: string;
  id?: number;
  componentId?: number;
  isComponentMachineEnded?: boolean;
  finishExecutor?: OrderExecutor;
};

type Variant = {
  id: string;
  name: string;
  mode: VariantMode;
  cycleInSeconds: number;
  reworkMode: boolean;
  pace: number;
};

interface MachineContext {
  machine?: Machine;
  setMachine: React.Dispatch<React.SetStateAction<Machine | undefined>>;
  machineMode: MachineMode | null;
  setMachineMode: React.Dispatch<React.SetStateAction<MachineMode | null>>;
  order: CurrentOrder | null;
  setOrder: React.Dispatch<React.SetStateAction<CurrentOrder | null>>;
  variant: Variant | null;
  setVariant: React.Dispatch<React.SetStateAction<Variant | null>>;
  isOrderAndVariantLoaded: boolean;
  setIsOrderAndVariantLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  isOrderAndVariantLoadingError: boolean;
  setIsOrderAndVariantLoadingError: React.Dispatch<React.SetStateAction<boolean>>;
  isAvailable: boolean;
  setIsAvailable: React.Dispatch<React.SetStateAction<boolean>>;
  isReworkMode: boolean;
  setIsReworkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const MachineContext = createContext<MachineContext | undefined>(undefined);

export function useMachineContext() {
  const context = useContext(MachineContext);
  if (context === undefined) {
    throw new Error('useMachineContext must be within MachineContextProvider');
  }

  return context;
}

export const MachineContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const history = useHistory();
  const [machine, setMachine] = useState(() => {
    if (history?.location?.pathname === paths.setMachine) {
      return undefined;
    }

    const selectedMachine = localStorage.getItem(LocalStorageKeys.SELECTED_MACHINE);

    return selectedMachine ? (JSON.parse(selectedMachine) as Machine) : undefined;
  });
  const [machineMode, setMachineMode] = useState<MachineMode | null>(null);
  const [order, setOrder] = useState<CurrentOrder | null>(null);
  const [variant, setVariant] = useState<Variant | null>(null);
  const [isAvailable, setIsAvailable] = useState(false);
  const [isReworkMode, setIsReworkMode] = useState(false);
  const [isOrderAndVariantLoaded, setIsOrderAndVariantLoaded] = useState(false);
  const [isOrderAndVariantLoadingError, setIsOrderAndVariantLoadingError] = useState(false);

  useEffect(() => {
    if (machine) {
      localStorage.setItem(LocalStorageKeys.SELECTED_MACHINE, JSON.stringify(machine));
    } else {
      localStorage.removeItem(LocalStorageKeys.SELECTED_MACHINE);
    }
  }, [machine]);

  const value: MachineContext = useMemo(
    () => ({
      machine,
      setMachine,
      machineMode,
      setMachineMode,
      order,
      setOrder,
      variant,
      setVariant,
      isOrderAndVariantLoaded,
      setIsOrderAndVariantLoaded,
      isOrderAndVariantLoadingError,
      setIsOrderAndVariantLoadingError,
      isAvailable,
      setIsAvailable,
      isReworkMode,
      setIsReworkMode
    }),
    [machine, machineMode, order, variant, isOrderAndVariantLoaded, isAvailable, isReworkMode]
  );

  return <MachineContext.Provider value={value}>{children}</MachineContext.Provider>;
};
