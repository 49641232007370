import React from 'react';
import { Box } from '@mui/material';

import useVisualViewportResizeHandler from 'Hooks/useVisualViewportResizeHandler';

interface PageContainerProps {
  height?: string;
}

const PageContainer: React.FC<PageContainerProps> = ({ children, height }) => {
  const viewportHeight = useVisualViewportResizeHandler();
  return (
    <Box mx={2} height={height ?? viewportHeight} pb={4}>
      {children}
    </Box>
  );
};

export default PageContainer;
