import { useQuery, UseQueryOptions } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { GetOrdersApiParams, OrderDto } from 'Types';

export const useOrdersQuery = (
  params: GetOrdersApiParams,
  options?: UseQueryOptions<OrderDto[], unknown, OrderDto[], ['orders', string | undefined, string | undefined]>
) => {
  return useQuery(
    ['orders', params.from, params.to],
    async () => {
      const { data } = await apiClient.getOrders(params);

      return data;
    },
    {
      ...options
    }
  );
};
