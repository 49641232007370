import React, { createContext, useContext, useMemo, useState } from 'react';

interface ReportsGeneratingContext {
  isGenerating: boolean;
  setIsGenerating: React.Dispatch<React.SetStateAction<boolean>>;
  isError: boolean;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  jobId: number | null;
  setJobId: React.Dispatch<React.SetStateAction<number | null>>;
}

const ReportsGeneratingContext = createContext<ReportsGeneratingContext | undefined>(undefined);

export const useReportsGeneratingContext = (): ReportsGeneratingContext => {
  const context = useContext(ReportsGeneratingContext);

  if (context === undefined) {
    throw new Error(' useReportsGeneratingContext must be used within a ReportsGeneratingContextProvider');
  }

  return context;
};

export const ReportsGeneratingContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [isError, setIsError] = useState(false);
  const [jobId, setJobId] = useState<number | null>(null);

  const value: ReportsGeneratingContext = useMemo(
    () => ({ isGenerating, setIsGenerating, jobId, setJobId, isError, setIsError }),
    [isGenerating, jobId, isError]
  );

  return <ReportsGeneratingContext.Provider value={value}>{children}</ReportsGeneratingContext.Provider>;
};
