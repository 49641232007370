import { useQuery, UseQueryOptions } from 'react-query';

import { authApiClient } from 'Api/AuthApiClient';
import { OperatorWorkstationAbilityToLogin } from 'Types';

export const useWorkstationAbilityToLogin = (
  id: string,
  params: { clientId: string },
  config: UseQueryOptions<
    OperatorWorkstationAbilityToLogin,
    unknown,
    OperatorWorkstationAbilityToLogin,
    ['workstation-ability-to-login', string]
  >
) =>
  useQuery(
    ['workstation-ability-to-login', id],
    async ({ signal }) => {
      const { data } = await authApiClient.workstationAbilityToLogin(id, params, { signal });

      return data;
    },
    config
  );
