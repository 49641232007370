import { FC } from 'react';

import SelectedAppHandlers from './SelectedAppHandlers';
import SharedHandlers from './SharedHandlers';

const GlobalStateHandlers: FC = () => {
  return (
    <>
      <SharedHandlers />
      <SelectedAppHandlers />
    </>
  );
};

export default GlobalStateHandlers;
