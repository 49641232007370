import { useMutation } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { queryClient } from 'Lib/react-query';

const useDeleteTechnology = () =>
  useMutation(
    async (id: string) => {
      await apiClient.deleteTechnology(id);
    },
    {
      onSuccess: () => queryClient.invalidateQueries('technologist-technologies')
    }
  );

export default useDeleteTechnology;
