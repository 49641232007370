import React, { useEffect, useState } from 'react';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { Box, BoxProps, ClassNameMap, IconButton, IconButtonProps, InputBase, styled } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  width: '231px',
  border: '2px solid',
  borderColor: theme.palette.primary.main,
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'stretch'
}));

const IconBox = styled(Box, { shouldForwardProp: (propName) => propName !== 'side ' })<{ side: 'left' | 'right' }>(
  ({ side, theme }) => ({
    width: '75px',
    textAlign: 'center',
    borderLeft: side === 'left' ? '2px solid' : 0,
    borderRight: side === 'left' ? 0 : '2px solid',
    borderColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  })
);

const StyledInput = styled(InputBase)(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  fontSize: '18px',
  fontWeight: theme.typography.fontWeightBold,
  maxWidth: 80,
  border: 'none',
  '& input': {
    textAlign: 'center'
  }
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary
}));

export type ManualNumericControlProps = {
  maxNumber?: number;
  minNumber?: number;
  defaultValue: number;
  height?: string;
  setInputValue?: (name: string, value: number) => void;
  onChange?: (value: number) => void;
  name?: string;
  disableFloat?: boolean;
  disabled?: boolean;
  classes?: Partial<ClassNameMap<'container' | 'iconBox' | 'iconButton' | 'input'>>;
  ContainerProps?: BoxProps;
  IconBoxProps?: BoxProps;
  IconButtonProps?: IconButtonProps;
};

const stringValueToNumber = (value: string): number => Number(value.replace(',', '.'));
const numberValueToString = (value: number): string => String(value).replace('.', ',');

const ManualNumericControl = ({
  maxNumber = 999,
  minNumber = 1,
  defaultValue,
  height = '3.25rem',
  setInputValue = () => {},
  onChange = () => {},
  name = '',
  disableFloat,
  disabled = false,
  ContainerProps,
  IconBoxProps,
  IconButtonProps
}: ManualNumericControlProps) => {
  const [value, setValue] = useState(numberValueToString(defaultValue));
  const numberValue = stringValueToNumber(value);
  const disableDecrease = numberValue <= minNumber || numberValue - 1 < minNumber;
  const disableIncrease = numberValue >= maxNumber || numberValue + 1 > maxNumber;

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueArr = e.target.value.split(',');
    const intValue = valueArr[0];
    const floatValue = valueArr[1];
    const re = disableFloat ? /^\d+$/ : /^\d+(,\d{0,2})?$/;

    if (e.target.value && !re.test(e.target.value)) return;
    if (valueArr.length > 2) return;
    if (floatValue && floatValue.length > 2) return;
    if (intValue && +intValue < minNumber) return;
    if (intValue && +intValue > maxNumber) return;
    if (intValue.length > 1 && intValue.startsWith('0')) return;
    if (intValue && +intValue === maxNumber && !/^\d+$/.test(e.target.value)) return;
    setValue(e.target.value);
  };

  const handleIncreaseValue = () => {
    setValue((prev) => {
      let intValue = Number(prev.split(',')[0]);
      const floatValue = prev.split(',')[1];
      if (intValue < maxNumber) {
        intValue++;
      }
      const newFloatValue = floatValue ? `,${floatValue}` : '';
      const newValue = `${intValue}${newFloatValue}`;

      return newValue;
    });
  };

  const handleDecreaseValue = () => {
    setValue((prev) => {
      let intValue = Number(prev.split(',')[0]);
      const floatValue = prev.split(',')[1];
      if (intValue > minNumber) {
        intValue--;
      }
      const newFloatValue = floatValue ? `,${floatValue}` : '';
      const newValue = `${intValue}${newFloatValue}`;

      return newValue;
    });
  };

  useEffect(() => {
    if (numberValue > maxNumber) {
      setValue(numberValueToString(maxNumber));
    }
  }, [numberValue, maxNumber]);

  useEffect(() => {
    setInputValue(name, numberValue);
    onChange(numberValue);
  }, [numberValue]);

  useEffect(() => {
    setValue(numberValueToString(defaultValue));
  }, [defaultValue]);

  return (
    <Container height={height} maxHeight={height} style={{ ...(disabled && { opacity: '0.3' }) }} {...ContainerProps}>
      <IconBox side='right' key='remove-icon' {...IconBoxProps}>
        <StyledIconButton
          data-testid='numeric-control-decrease-value'
          onClick={handleDecreaseValue}
          disabled={disabled || disableDecrease}
          {...IconButtonProps}
        >
          <RemoveIcon />
        </StyledIconButton>
      </IconBox>
      <StyledInput
        name={name}
        type='text'
        value={value}
        onChange={handleInput}
        disabled={disabled}
        onBlur={() => !value && setValue(String(minNumber).replace('.', ','))}
        inputProps={{
          ['data-testid']: 'numeric-control-input'
        }}
      />
      <IconBox side='left' key='add-icon' {...IconBoxProps}>
        <StyledIconButton
          data-testid='numeric-control-increase-value'
          onClick={handleIncreaseValue}
          disabled={disabled || disableIncrease}
          {...IconButtonProps}
        >
          <AddIcon />
        </StyledIconButton>
      </IconBox>
    </Container>
  );
};

export default ManualNumericControl;
