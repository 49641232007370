import { FieldErrors, FieldValues, Path, UseFormGetFieldState, UseFormTrigger } from 'react-hook-form';

export type FormStepFields<TFieldValues extends FieldValues, TStepNumber extends number> = Record<
  TStepNumber,
  Path<TFieldValues>[]
>;

export const getIsValidStepFactory =
  <TFieldValues extends FieldValues, TStepNumber extends number>({
    stepFields,
    getFieldState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    errors: _
  }: {
    stepFields: FormStepFields<TFieldValues, TStepNumber>;
    getFieldState: UseFormGetFieldState<TFieldValues>;
    /** Not used explicitly, but needed to enforce subscription to errors for getFieldState to be reactive to state update */
    errors: FieldErrors<TFieldValues>;
  }) =>
  (step: TStepNumber) => {
    const fields = stepFields[step];

    return fields.every((field) => !getFieldState(field).invalid);
  };

export const getTriggerStepValidationFactory =
  <TFieldValues extends FieldValues, TStepNumber extends number>(
    trigger: UseFormTrigger<TFieldValues>,
    stepFields: FormStepFields<TFieldValues, TStepNumber>
  ) =>
  async (step: TStepNumber) => {
    const fields = stepFields[step];

    return trigger(fields);
  };
