import { useMutation } from 'react-query';

import { apiClient } from 'Api/ApiClient';

const useAccidentConfirmation = () =>
  useMutation(async (id: string) => {
    await apiClient.postIncidentConfirmation(id);
  });

export default useAccidentConfirmation;
