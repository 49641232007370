import { useQuery, UseQueryOptions } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { FeatureFlags, GetFeatureFlagsApiResponse } from 'Types/Configuration';

const mapGetFeatureFlagsApiResponseToFeatureFlags = (data: GetFeatureFlagsApiResponse): FeatureFlags => {
  return Object.fromEntries(new Map(data.map(({ name, isEnabled }) => [name, isEnabled]))) as FeatureFlags;
};

export const useGetFeatureFlagsQuery = (
  config?: UseQueryOptions<GetFeatureFlagsApiResponse, unknown, FeatureFlags, ['feature-flags']>
) =>
  useQuery(
    ['feature-flags'],
    async ({ signal }) => {
      const { data } = await apiClient.getFeatureFlags({ signal });

      return data;
    },
    {
      select: mapGetFeatureFlagsApiResponseToFeatureFlags,
      ...config
    }
  );
