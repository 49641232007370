import 'devextreme/dist/css/dx.light.css';
import './Lib/i18n';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import config from 'devextreme/core/config';

import AuthManager from 'Auth/AuthManager';
import OpenedTabsMonitor from 'Auth/OpenedTabsMonitor';
import SessionMonitor from 'Auth/SessionMonitor';
import Analytics from 'Lib/Analytics';
import ErrorTracker from 'Lib/error-tracker';

import App from './App';
import { licenseKey } from './devextreme-license';

if ((licenseKey as string) !== 'YOUR_DEVEXTREME_LICENSE_KEY') config({ licenseKey }); // validate DevExtreme license key
ErrorTracker.init();
Analytics.init();

OpenedTabsMonitor.init();
AuthManager.init();
SessionMonitor.init();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
