import React, { useCallback, useContext, useLayoutEffect, useMemo, useRef } from 'react';
import { VariableSizeList } from 'react-window';

import { useResizeObserver } from 'Hooks/useResizeObserver';
import { AnyType } from 'Types';

type SizeMapCacheContext = {
  getItemSize: (index: number) => number;
  setItemSize: (index: number, size: number) => void;
  setListRef: (node: VariableSizeList | null) => void;
};

const SizeMapCacheContext = React.createContext<SizeMapCacheContext | undefined>(undefined);

export function useSizeMapCacheContext() {
  const context = useContext(SizeMapCacheContext);
  if (context === undefined) {
    throw new Error('useSizeMapCacheContext must be within SizeMapCacheContextProvider');
  }

  return context;
}

type IsFunction<T> = T extends (...args: AnyType[]) => AnyType ? T : never;
const isFunction = <T,>(value: T): value is IsFunction<T> => typeof value === 'function';

export const SizeMapCacheProvider: React.FC<{
  children: (data: SizeMapCacheContext) => React.ReactElement;
  defaultItemSize: number;
  initialSizeMap?: { [key: string]: number };
}> = ({ children, defaultItemSize, initialSizeMap }) => {
  const sizeMap = useRef<{ [key: string]: number }>(initialSizeMap ?? {});
  const listRef = useRef<VariableSizeList | null>(null);
  const setListRef = useCallback((node) => {
    if (node) {
      listRef.current = node;
    }
  }, []);
  const setItemSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    if (listRef.current) {
      listRef.current.resetAfterIndex(Math.max(0, index - 1));
    }
  }, []);
  const getItemSize = useCallback((index: number) => sizeMap.current[index] || defaultItemSize, []);
  const value = useMemo(() => ({ getItemSize, setItemSize, setListRef }), [setItemSize, getItemSize, setListRef]);

  if (!isFunction(children)) {
    throw new Error('children is mandatory and needs to be a function');
  }

  return (
    <SizeMapCacheContext.Provider value={value}>
      {children({ getItemSize, setItemSize, setListRef })}
    </SizeMapCacheContext.Provider>
  );
};

export const useSizeMapMeasuredItemRef = (index: number) => {
  const ref = useRef<HTMLElement | null>(null);
  const { height } = useResizeObserver(ref);
  const { setItemSize, getItemSize } = useSizeMapCacheContext();

  const setLegacyRef = useCallback((node: HTMLElement | null) => {
    ref.current = node;
    if (node && getItemSize(index) !== node.getBoundingClientRect().height) {
      setItemSize(index, node.getBoundingClientRect().height);
    }
  }, []);

  useLayoutEffect(() => {
    if (height && ref.current && getItemSize(index) !== ref.current.getBoundingClientRect().height) {
      setItemSize(index, ref.current.getBoundingClientRect().height);
    }
  }, [height]);

  return { ref, setLegacyRef };
};
