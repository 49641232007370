import React, { Component, ErrorInfo } from 'react';
import { ErrorBoundary as SentryErrorBoundary, ErrorBoundaryProps as SentryErrorBoundaryProps } from '@sentry/react';

import ErrorTracker from './ErrorTracker';
import FullSizeErrorFallback from './FullSizeErrorFallback';

/**
 * Props interface for defining properties passed to a React component.
 *
 * @typedef {Object} Props
 * @property {React.ReactNode} children - React nodes that are passed as children to the component.
 * @property {SentryErrorBoundaryProps['fallback']} [fallback] - Optional fallback component or function used for error boundary.
 */
type Props = {
  children: React.ReactNode;
  fallback?: SentryErrorBoundaryProps['fallback'];
};

/**
 * Represents the state of a component or system.
 *
 * @typedef {Object} State
 * @property {boolean} hasError - Indicates whether an error has occurred.
 */
type State = {
  hasError: boolean;
};

/**
 * Represents properties required for a fallback mechanism.
 *
 * This type is used to define the essential properties that are needed to implement a fallback
 * strategy within the application, ensuring that there is a predefined fallback behavior in case
 * a planned event encounters issues.
 *
 * Properties:
 * - eventId: A unique identifier for the event that the fallback is associated with.
 */
type FallbackProps = {
  eventId: string;
};

/**
 * Default fallback component for error boundaries.
 *
 * This function returns a `FullSizeErrorFallback` component, which is designed
 * to be rendered when an error occurs. It provides a user-friendly interface
 * to inform users about the error.
 *
 * @param {FallbackProps} props - The properties passed to the fallback component.
 * @param {string} props.eventId - A unique identifier for the event where the error occurred.
 *
 * @returns {React.Element} The rendered `FullSizeErrorFallback` component.
 */
const defaultFallback = ({ eventId }: FallbackProps) => <FullSizeErrorFallback eventId={eventId} />;

/**
 * A React component that acts as an error boundary to catch JavaScript errors
 * anywhere in its child component tree, log those errors, and display a fallback UI.
 *
 * @class
 * @extends Component
 * @param {Props} props - The properties passed to the component.
 * @param {State} state - The state of the component.
 */
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.handleError(error, errorInfo);
  }

  handleError(error: Error, errorInfo: ErrorInfo) {
    ErrorTracker.captureException(error, { componentStack: errorInfo.componentStack });
  }

  render() {
    const { children, fallback = defaultFallback } = this.props;

    return <SentryErrorBoundary fallback={fallback}>{children}</SentryErrorBoundary>;
  }
}

export default ErrorBoundary;
