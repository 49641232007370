import { useMutation } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { InvalidBunch } from 'Types';

type Data = {
  machineId: string;
  potentialNOKs: InvalidBunch[];
};

const useReportPotentialNOKs = () =>
  useMutation(async (data: Data) => {
    await apiClient.postPotentialNOKs(data.machineId, { potentialNOKs: data.potentialNOKs });
  });

export default useReportPotentialNOKs;
