import { useQuery, UseQueryOptions } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import type { OrderDto } from 'Types';

export const useOrderDetailsQuery = (
  id: number,
  options?: UseQueryOptions<OrderDto, unknown, OrderDto, ['order-details', number]>
) =>
  useQuery(
    ['order-details', id],
    async () => {
      const { data } = await apiClient.getOrderDetails(id);

      return data;
    },
    options
  );
