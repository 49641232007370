import React from 'react';
import { Box, TableRowProps } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { flexRender, Row } from '@tanstack/react-table';

import { useSizeMapMeasuredItemRef } from 'Components/SizeMapCache/SizeMapCacheContext';
import { AnyType } from 'Types';

import ReactTableRow from './ReactTableRow';

export const ReactTableVirtualizedRow = <T extends Record<string, unknown>>({
  row,
  rowIndex,
  showBorder = false,
  minWidth = 0,
  expandableTable = false,
  even,
  selected,
  onClick,
  sx
}: {
  row: Row<T>;
  rowIndex: number;
  showBorder?: boolean;
  minWidth?: number;
  expandableTable?: boolean;
  even?: boolean;
  selected?: boolean;
  onClick?: () => void;
  sx?: TableRowProps['sx'];
}) => {
  return (
    <ReactTableRow
      expandableTable={expandableTable}
      depth={row.depth}
      even={typeof even === 'boolean' ? even : rowIndex % 2 === 0}
      onClick={onClick}
      selected={selected}
      hover
      showBorder={showBorder}
      tabIndex={-1}
      component='div'
      sx={{ display: 'flex', flex: '1 0 auto', minWidth, ...sx }}
    >
      {row.getVisibleCells().map((cell) => {
        return (
          <TableCell
            key={cell.id}
            component='div'
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: cell.column.getSize(),
              minWidth: cell.column.getSize(),
              flex: `${cell.column.getSize()} 0 auto`
            }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </TableCell>
        );
      })}
    </ReactTableRow>
  );
};

const ReactTableVirtualizedVariableSizeRow = React.memo(
  <T extends Record<string, unknown>>({
    index,
    style,
    rows,
    renderRow,
    minWidth,
    expandableTable = false
  }: {
    index: number;
    style: React.CSSProperties | undefined;
    rows: Row<T>[];
    renderRow: (row: Row<T>, rowIndex: number) => AnyType;
    minWidth: number;
    expandableTable?: boolean;
  }) => {
    const row: Row<T> = rows[index];
    const { ref } = useSizeMapMeasuredItemRef(index);

    return (
      <div key={`item-${row.id}`} style={style}>
        <Box ref={ref}>
          {renderRow ? (
            renderRow(row, index)
          ) : (
            <ReactTableVirtualizedRow
              row={row}
              rowIndex={index}
              minWidth={minWidth}
              expandableTable={expandableTable}
            />
          )}
        </Box>
      </div>
    );
  }
);

export default ReactTableVirtualizedVariableSizeRow;
