import { useEffect, useState } from 'react';

import useDebounce from './useDebounce';

const useVisualViewportResizeHandler = () => {
  const initialHeight = `${window.innerHeight}px`;
  const [correctViewportHeight, setCorrectViewportHeight] = useState(initialHeight);
  const debouncedViewportHeight = useDebounce(correctViewportHeight, 500);

  const handleResize = () => {
    setCorrectViewportHeight(initialHeight);
  };

  useEffect(() => {
    if ('visualViewport' in window) {
      window.visualViewport?.addEventListener('resize', handleResize);
    }

    return () => {
      if ('visualViewport' in window) {
        window.visualViewport?.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return debouncedViewportHeight;
};

export default useVisualViewportResizeHandler;
