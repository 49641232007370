import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';

interface NotificationContext {
  notificationsCount: number;
  incrementNotificationCount: () => void;
  setNotificationsCount: React.Dispatch<React.SetStateAction<number>>;
  onRead: (id: string) => void;
  getReadNotificationIds: () => string[];
  clearReadNotificationIds: () => void;
}

const NotificationContext = createContext<NotificationContext | undefined>(undefined);

export const useNotificationContext = (): NotificationContext => {
  const context = useContext(NotificationContext);

  if (context === undefined) {
    throw new Error('useNotificationContext must be used within a NavigationContextProvider');
  }

  return context;
};

export const NotificationContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notificationsCount, setNotificationsCount] = useState(0);
  const readNotificationIdsRef = useRef<Set<string>>(new Set());

  const incrementNotificationCount = useCallback(() => {
    setNotificationsCount((prev) => prev + 1);
  }, []);

  const onRead = useCallback((id: string) => {
    readNotificationIdsRef.current.add(id);
  }, []);

  const getReadNotificationIds = useCallback(() => {
    return Array.from(readNotificationIdsRef.current);
  }, []);

  const clearReadNotificationIds = useCallback(() => {
    readNotificationIdsRef.current.clear();
  }, []);

  const value: NotificationContext = useMemo(
    () => ({
      notificationsCount,
      setNotificationsCount,
      incrementNotificationCount,
      onRead,
      getReadNotificationIds,
      clearReadNotificationIds
    }),
    [notificationsCount, incrementNotificationCount, onRead, getReadNotificationIds, clearReadNotificationIds]
  );

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};
