const HubMethods = {
  ProductionLineBecameRemovable: 'ProductionLineBecameRemovable',
  ProductionLineBecameNonRemovable: 'ProductionLineBecameNonRemovable',
  VariantBecameRemovable: 'VariantBecameRemovable',
  VariantBecameEditable: 'VariantBecameEditable',
  VariantBecameNonRemovable: 'VariantBecameNonRemovable',
  VariantBecameNonEditable: 'VariantBecameNonEditable',
  MachineProducedUnitUpdated: 'MachineProducedUnitUpdated',
  MachineDeleted: 'MachineDeleted',
  MachineDeactivated: 'MachineDeactivated',
  MachineActivated: 'MachineActivated',
  MachineModeChanged: 'MachineModeChanged',
  MachineFastLoggingEnabled: 'MachineFastLoggingEnabled',
  MachineDashboard: 'MachineDashboard',
  MachineAssignedToProductionLine: 'MachineAssignedToProductionLine',
  MachineUnassignedFromProductionLine: 'MachineUnassignedFromProductionLine',
  MachineVariantEditable: 'MachineVariantEditable',
  ProductionLineRemoved: 'ProductionLineRemoved',
  ProductionLineUpdated: 'ProductionLineUpdated',
  RequiredFileUpdated: 'RequiredFileUpdated',
  RequiredFileCreated: 'RequiredFileCreated',
  ComplaintFileExpirationDateChanged: 'ComplaintFileExpirationDateChanged',
  ComplaintFileProductionLinesChanged: 'ComplaintFileProductionLinesChanged',
  ComplaintFileExpired: 'ComplaintFileExpired',
  ComplaintFileDeleted: 'ComplaintFileDeleted',
  ComplaintFileCreated: 'ComplaintFileCreated',
  InvalidComponentsReported: 'InvalidComponentsReported',
  FileDeleted: 'FileDeleted',
  ProductionLineForemanLayoutAdded: 'ProductionLineForemanLayoutAdded',
  ProductionLineForemanLayoutUpdated: 'ProductionLineForemanLayoutUpdated',
  ProductionLineForemanLayoutRemoved: 'ProductionLineForemanLayoutRemoved',
  ProductionLineMaintenanceLayoutAdded: 'ProductionLineMaintenanceLayoutAdded',
  ProductionLineMaintenanceLayoutUpdated: 'ProductionLineMaintenanceLayoutUpdated',
  ProductionLineMaintenanceLayoutRemoved: 'ProductionLineMaintenanceLayoutRemoved',
  ProductionLineNotificationAdded: 'ProductionLineNotificationAdded',
  BunchCancelled: 'BunchCancelled',
  BunchProduced: 'BunchProduced',
  FailureBunchProduced: 'FailureBunchProduced',
  InvalidUnitsReported: 'InvalidUnitsReported',
  StartedNormalWork: 'StartedNormalWork',
  StartedWorkWithFailure: 'StartedWorkWithFailure',
  StartedFailure: 'StartedFailure',
  StartedIdle: 'StartedIdle',
  StartedRetool: 'StartedRetool',
  SuspendedRetoolStarted: 'SuspendedRetoolStarted',
  SuspendedRetoolEnded: 'SuspendedRetoolEnded',
  RetoolEnded: 'RetoolEnded',
  IdleWasSetOnWorkstation: 'IdleWasSetOnWorkstation',
  ReportedNewIdleReason: 'ReportedNewIdleReason',
  SetUnclassifiedIdleReason: 'SetUnclassifiedIdleReason',
  WorkstationStoppedWorking: 'WorkstationStoppedWorking',
  WorkstationStartedWorking: 'WorkstationStartedWorking',
  MicroidleSet: 'MicroidleSet',
  MicroidleEnded: 'MicroidleEnded',
  IdleEnded: 'IdleEnded',
  OrderComponentMachineGoalRecalculated: 'OrderComponentMachineGoalRecalculated',
  OrderNameChanged: 'OrderNameChanged',
  ProductionGoalRecalculated: 'ProductionGoalRecalculated',
  RfidCardApplied: 'RfidCardApplied',
  MultiOperatorCardApplied: 'MultiOperatorCardApplied',
  WorkstationFailureReportAdded: 'WorkstationFailureReportAdded',
  WorkstationFailureReportsHandled: 'WorkstationFailureReportsHandled',
  WorkstationFailureReportsUnhandled: 'WorkstationFailureReportsUnhandled',
  WorkstationFailureReportsTerminated: 'WorkstationFailureReportsTerminated',
  IncidentCreated: 'IncidentCreated',
  KanbanTaskCreated: 'KanbanTaskCreated',
  KanbanTaskDeleted: 'KanbanTaskDeleted',
  KanbanTaskUpdated: 'KanbanTaskUpdated',
  KanbanTaskURCreated: 'KanbanTaskURCreated',
  KanbanTaskURDeleted: 'KanbanTaskURDeleted',
  KanbanTaskURUpdated: 'KanbanTaskURUpdated',
  RepairTaskAdded: 'RepairTaskAdded',
  RepairTaskStarted: 'RepairTaskStarted',
  RepairTaskStopped: 'RepairTaskStopped',
  RepairTaskFinishedWithFailure: 'RepairTaskFinishedWithFailure',
  RepairTaskFinishedWithSuccess: 'RepairTaskFinishedWithSuccess',
  MaintenanceTaskAdded: 'MaintenanceTaskAdded',
  MaintenanceTaskStarted: 'MaintenanceTaskStarted',
  MaintenanceTaskStopped: 'MaintenanceTaskStopped',
  MaintenanceTaskFinishedWithFailure: 'MaintenanceTaskFinishedWithFailure',
  MaintenanceTaskFinishedWithSuccess: 'MaintenanceTaskFinishedWithSuccess',
  UserAssignedToTask: 'UserAssignedToTask',
  UserUnassignedFromTask: 'UserUnassignedFromTask',
  TaskTimeChanged: 'TaskTimeChanged',
  ReworkBunchCyclesProduced: 'ReworkBunchCyclesProduced',
  ReworkBunchReported: 'ReworkBunchReported',
  WorkstationNotificationDeliveryAdded: 'WorkstationNotificationDeliveryAdded',
  UserNotificationDeliveryAdded: 'UserNotificationDeliveryAdded',
  ComplaintAssignedToProductionLine: 'ComplaintAssignedToProductionLine',
  ComplaintUnassignedFromProductionLine: 'ComplaintUnassignedFromProductionLine',
  DataLoggerStatusChanged: 'DataLoggerStatusChanged',
  WorkstationRetooled: 'WorkstationRetooled',
  ShiftConfigurationEffectiveToChanged: 'ShiftConfigurationEffectiveToChanged',
  PotentialNokProduced: 'PotentialNokProduced',
  DisconnectPairedMachine: 'DisconnectPairedMachine',
  URFileUpdated: 'URFileUpdated',
  URFileCreated: 'URFileCreated',
  URFileDeleted: 'URFileDeleted',
  ReportPrepared: 'ReportPrepared',
  ReportNotPrepared: 'ReportNotPrepared',
  OperatorWorkStarted: 'OperatorWorkStarted',
  OperatorWorkEnded: 'OperatorWorkEnded',
  OrderDeleted: 'OrderDeleted',
  OrderUpdated: 'OrderUpdated',
  OrderCreated: 'OrderCreated',
  OrderComponentValidUnitsCountChanged: 'OrderComponentValidUnitsCountChanged',
  OrderComponentMachineCompleted: 'OrderComponentMachineCompleted',
  OrderComponentMachineResumed: 'OrderComponentMachineResumed',
  MachineAddedToOrder: 'MachineAddedToOrder',
  MachineRemovedFromOrder: 'MachineRemovedFromOrder',
  PlannedComponentMachineEndDateChanged: 'PlannedComponentMachineEndDateChanged',
  WorkstationPotentialFailureRegistered: 'WorkstationPotentialFailureRegistered',
  SuspendedRetoolResumed: 'SuspendedRetoolResumed',
  OrderStartedBarcodeScanned: 'OrderStartedBarcodeScanned',
  OrderCompletedBarcodeScanned: 'OrderCompletedBarcodeScanned',
  ProductionLineCheckpointScanned: 'ProductionLineCheckpointScanned',
  OrderSerialNumberScanned: 'OrderSerialNumberScanned',
  BarcodeValidationError: 'BarcodeValidationError',
} as const;

type HubMethodsKeys = keyof typeof HubMethods;
export type HubMethodName = (typeof HubMethods)[HubMethodsKeys];

export default HubMethods;
