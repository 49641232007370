import { getI18n } from 'react-i18next';
import { z } from 'zod';

import { validateDateRange } from 'Helpers/Validation';

export const ORDER_MIN_AMOUNT = 1;

const orderTechnologySchema = z.object({
  technologyId: z
    .string({
      required_error: getI18n().t('messages.thisFieldIsRequired')
    })
    .min(1, getI18n().t('messages.thisFieldIsRequired'))
});

const orderFormBaseSchema = z.object({
  name: z
    .string({
      required_error: getI18n().t('messages.thisFieldIsRequired')
    })
    .trim()
    .min(1, getI18n().t('messages.theMinimumNumberOfCharactersIs', { length: 1 }))
    .max(50, getI18n().t('messages.theMaximumNumberOfCharactersIs', { length: 50 })),
  amount: z.number(),
  startDate: z.date({
    invalid_type_error: getI18n().t('messages.invalidDateFormat'),
    required_error: getI18n().t('messages.thisFieldIsRequired')
  }),
  endDate: z.date({
    invalid_type_error: getI18n().t('messages.invalidDateFormat'),
    required_error: getI18n().t('messages.thisFieldIsRequired')
  }),
  isStarted: z.boolean().nullable().optional(),
  isCompleted: z.boolean().nullable().optional(),
  serialNumber: z.string().nullable().optional()
});

export const getOrderFormSchema = ({
  minAmount,
  incompleteTechnologyIds
}: {
  minAmount: number;
  incompleteTechnologyIds: string[];
}) => {
  const technologyValidation = orderTechnologySchema.superRefine(({ technologyId }, { addIssue }) => {
    if (technologyId && incompleteTechnologyIds.includes(technologyId)) {
      addIssue({
        code: z.ZodIssueCode.custom,
        message: getI18n().t('messages.selectATechnologyThatCanBeCompleted'),
        path: ['technologyId']
      });
    }
  });

  const orderBaseValidation = orderFormBaseSchema.superRefine(({ amount, startDate, endDate }, { addIssue }) => {
    const amountValidationResult = z
      .number()
      .min(minAmount, { message: getI18n().t('messages.valueMustBeGreaterThan', { value: minAmount }) })
      .max(100_000)
      .safeParse(amount);

    if (!amountValidationResult.success) {
      amountValidationResult.error.errors.forEach((error) => {
        addIssue({
          code: z.ZodIssueCode.custom,
          message: error.message,
          path: ['amount']
        });
      });
    }

    validateDateRange({ startDate, endDate }, { addIssue });
  });

  return z.intersection(orderBaseValidation, technologyValidation);
};

const orderSchema = orderFormBaseSchema.merge(orderTechnologySchema);

export type OrderFormValues = z.infer<typeof orderSchema>;
