import React from 'react';
import { Box } from '@mui/material';

const PageHeaderActions: React.FC = ({ children }) => (
  <Box display='flex' gap={1.5}>
    {children}
  </Box>
);

export default PageHeaderActions;
