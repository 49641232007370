import * as Sentry from '@sentry/react';
import Config from 'config';

import { logErrorToBackend, logInfoToBackend } from 'Hooks/onPremiseLogs/logToBackend';
import { AdditionalInfo } from 'Hooks/onPremiseLogs/logTypes';

/**
 * Configuration settings for initializing Sentry.
 *
 * @type {Object}
 * @property {string} dsn - Data Source Name for Sentry configuration.
 * @property {string} environment - The current host environment where the application is running.
 * @property {string} release - The current version of the application.
 * @property {Array<string>} ignoreErrors - List of error messages to be ignored by Sentry.
 */
const initSentrySettings: object = {
  dsn: Config.SENTRY_DSN,
  environment: window.location.host,
  release: Config.APP_VERSION,
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.']
};

/**
 * Initializes Sentry with predefined settings and sets a release tag.
 *
 * This function configures Sentry error reporting using the specified
 * settings from `initSentrySettings`. It also tags the reported issues
 * with a `ReleaseTag` to distinguish different versions or releases
 * of the application.
 *
 * Dependencies:
 * - Sentry: The error tracking library that needs to be configured.
 * - initSentrySettings: A configuration object for initializing Sentry.
 * - Config.RELEASE_TAG: A release tag used for identifying the version
 *   of the application in Sentry reports.
 */
const initSentry = () => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init(initSentrySettings);
    Sentry.setTag('ReleaseTag', Config.RELEASE_TAG);
  }
};

/**
 * Retrieves the logging system configuration.
 *
 * The logging system is determined by the LOGGING_SYSTEM environment variable.
 * If the environment variable is not set, the default logging system 'SENTRY' is returned.
 *
 * @returns {string} The logging system configuration.
 */
const getLoggingSystem = (): string => process.env.LOGGING_SYSTEM ?? 'SENTRY';
/**
 * The `ErrorTracker` object provides methods to initialize a logging system and capture errors or messages.
 *
 * The logging system can be switched between Sentry and a custom backend based on configuration.
 *
 * - `init()`: Initializes the logging system based on configuration.
 * - `captureException(error, additionalInfo)`: Captures and logs an exception.
 * - `captureMessage(message, additionalInfo)`: Captures and logs a message.
 *
 * @property {Function} init - Initializes the logging system based on configuration.
 * @property {Function} captureException - Captures and logs an exception.
 * @property {Function} captureMessage - Captures and logs a message.
 */
const ErrorTracker = {
  init: () => {
    const loggingSystem = getLoggingSystem();
    if (loggingSystem === 'SENTRY' && Config.SENTRY_DSN) {
      initSentry();
    } else {
      logInfoToBackend('Logging via backend');
    }
  },
  captureException: (error: Error, additionalInfo: AdditionalInfo = {}) => {
    const loggingSystem = getLoggingSystem();
    if (loggingSystem === 'SENTRY' && Config.SENTRY_DSN) {
      Sentry.captureException(error, {
        tags: additionalInfo.tags || {},
        extra: additionalInfo.extra || {},
        level: additionalInfo.level || 'error'
      });
    } else {
      logErrorToBackend(error, additionalInfo);
    }
  },
  captureMessage: (message: string, additionalInfo: AdditionalInfo = {}) => {
    const loggingSystem = getLoggingSystem();
    if (loggingSystem === 'SENTRY' && Config.SENTRY_DSN) {
      Sentry.captureMessage(message, {
        tags: additionalInfo.tags || {},
        extra: additionalInfo.extra || {},
        level: additionalInfo.level || 'info'
      });
    } else {
      logInfoToBackend(message, additionalInfo);
    }
  }
};

export default ErrorTracker;
