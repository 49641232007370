import { useQuery, UseQueryOptions } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { ActiveTranslationDto } from 'Types';

const useActiveTranslationsOfLanguages = (
  queryOptions?: UseQueryOptions<
    ActiveTranslationDto[],
    unknown,
    ActiveTranslationDto[],
    ['admin-active-translations-of-languages']
  >
) =>
  useQuery(
    ['admin-active-translations-of-languages'],
    async () => {
      const { data } = await apiClient.getActiveTranslationsOfLanguages();

      return data;
    },
    queryOptions
  );

export default useActiveTranslationsOfLanguages;
