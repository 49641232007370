import { useMutation } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { queryClient } from 'Lib/react-query';

type RemoveOrderComponentMachine = {
  orderId: number;
  orderComponentId: number;
  orderComponentMachineId: string;
};

export const useRemoveOrderComponentMachine = () =>
  useMutation(
    async ({ orderId, orderComponentId, orderComponentMachineId }: RemoveOrderComponentMachine) => {
      await apiClient.removeOrderComponentMachine(orderId, orderComponentId, orderComponentMachineId);
    },
    {
      onError: () => queryClient.invalidateQueries('orders')
    }
  );
