import { useQuery, UseQueryOptions } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { VariantsWithMachinesApiParams, VariantsWithMachinesApiResponse } from 'Types';

export const useVariantsWithMachinesQuery = (
  params: VariantsWithMachinesApiParams,
  options?: UseQueryOptions<
    VariantsWithMachinesApiResponse,
    unknown,
    VariantsWithMachinesApiResponse,
    'variants-with-machines'
  >
) =>
  useQuery(
    'variants-with-machines',
    async () => {
      const { data } = await apiClient.getVariantsWithMachines(params);

      return data;
    },
    options
  );
