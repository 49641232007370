import React from 'react';
import { Box, Typography } from '@mui/material';
import { BaseToolbarProps } from '@mui/x-date-pickers/internals';

import { AnyType } from 'Types';

type Views = 'day' | 'hours' | 'minutes' | 'month' | 'seconds' | 'year';

type Props = {
  openView: Views;
  views: readonly Views[];
  date: Date;
};

export const CustomDateTimePickerToolbarComponent = ({
  parsedValue,
  views,
  openView
}: BaseToolbarProps<AnyType, AnyType>) => (
  <CustomDateTimePickerToolbar date={parsedValue} openView={openView} views={views} />
);

const CustomDateTimePickerToolbar: React.FC<Props> = ({ openView, views, date }) => {
  const month = date?.toLocaleString('default', { month: 'long' });
  const year = date?.getFullYear();
  const hours = date?.getHours() === 0 ? '00' : date?.getHours();
  const minutes = `0${date?.getMinutes()}`.slice(-2);
  const showOnlyTime = ['day', 'month', 'year'].every((value) => !views.includes(value as Views));
  const shouldShowToolbar = openView === 'hours' || openView === 'minutes';

  if (!shouldShowToolbar) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        paddingTop: '10px',
        height: '40px'
      }}
    >
      {date && (
        <>
          {!showOnlyTime && (
            <Typography
              color='text.primary'
              sx={{ fontSize: '18px', textTransform: 'capitalize', marginRight: '30px' }}
            >
              {`${month} ${year}`}
            </Typography>
          )}
          <Typography color='text.primary' sx={{ fontSize: '18px' }}>
            <span style={{ fontWeight: openView === 'hours' ? 700 : 400 }}>{hours}</span>:
            <span style={{ fontWeight: openView === 'minutes' ? 700 : 400 }}>{minutes}</span>
          </Typography>
        </>
      )}
    </Box>
  );
};

export default CustomDateTimePickerToolbar;
