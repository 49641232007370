import { useMutation } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { queryClient } from 'Lib/react-query';
import { PutTechnologyDto } from 'Types';

const useUpdateTechnology = () =>
  useMutation(
    async (data: { id: string; technology: PutTechnologyDto }) => {
      await apiClient.updateTechnology(data.id, data.technology);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('technologist-technologies');
      }
    }
  );

export default useUpdateTechnology;
