import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const PaperCardHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch'
});

export default PaperCardHeader;
