import React, { useRef, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput as MuiOutlinedInput,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps,
  styled
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

import Tooltip from 'Components/Tooltip';
import { AnyType } from 'Types';

const OutlinedInput = styled(MuiOutlinedInput)(({ theme }) => ({
  borderRadius: '8px',
  height: '3.125rem',
  [theme.breakpoints.up('xxl')]: {
    height: '5rem'
  }
}));

type OptionValue = string | number;

type Option<T extends OptionValue> = {
  id: T;
  name: string;
};

export interface MultiSelectProps<T extends OptionValue> extends Omit<SelectProps, 'onChange'> {
  value?: Option<T>[];
  options: Option<T>[];
  onChange?: (value: Option<T>[]) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  name?: string;
  label?: string;
  selectAll?: boolean;
  search?: boolean;
  height?: string;
  inputHidden?: boolean;
  showAllText?: string;
  disabledOptions?: T[];
  optionDisabledInfo?: string;
  helperText?: string;
  error?: boolean;
}

const HrStyled = styled('hr')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderTopWidth: '0px'
}));

const MultiSelect = <T extends OptionValue>({
  options = [],
  value = [],
  onChange = (param: Option<T>[]) => param,
  disabled = false,
  fullWidth = false,
  name = '',
  label = '',
  selectAll = false,
  search = false,
  height = 'full',
  inputHidden = false,
  showAllText = getI18n().t('labels.all'),
  disabledOptions = [],
  optionDisabledInfo = '',
  helperText = '',
  error = false,
  id,
  ...props
}: MultiSelectProps<T>) => {
  const [searchText, setSearchText] = useState('');
  const fieldId = id ?? `${name}-multi-select`;
  const labelId = `${fieldId}-label`;

  const filtered = options.filter((o) => o.name.toLowerCase().includes(searchText.toLowerCase()));
  const filterRef = useRef<HTMLInputElement>();
  const { t } = useTranslation();

  const stopPropagation = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        break;
      default:
        e.stopPropagation();
    }
  };

  const moveFocusToInput = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter' || e.key === 'ArrowRight' || e.key === 'Tab') {
      e.stopPropagation();
      e.preventDefault();
      if (filterRef?.current) {
        filterRef?.current?.focus();
      }
    }
  };

  const handleToggle = (e: SelectChangeEvent<unknown>) => {
    const values = e.target.value as Option<T>[];
    const currentlySelected = values[values.length - 1];

    if (values.length && currentlySelected.id === 'all') {
      onChange(values.length - 1 === options.length ? options.filter((o) => disabledOptions.includes(o.id)) : options);

      return;
    }

    if (values.filter((v) => v.id === currentlySelected.id).length > 1) {
      onChange(values.filter((v) => v.id !== currentlySelected.id));
    } else {
      onChange(values);
    }
  };

  const showSelectAll = selectAll && options.length > 1;
  const allSelected = value.length === options.length;

  return (
    <FormControl error={error} fullWidth={fullWidth}>
      {!inputHidden && <InputLabel id={labelId}>{label}</InputLabel>}
      <MuiSelect
        fullWidth
        multiple
        value={value}
        data-testid='multiselect-option'
        disabled={disabled}
        onChange={handleToggle}
        renderValue={() =>
          allSelected && options.length > 1
            ? t('labels.all', { context: 'feminine' })
            : value.map((elem) => elem.name).join(', ')
        }
        id={id}
        labelId={labelId}
        input={
          <MuiOutlinedInput
            name={name}
            label={label}
            style={{
              visibility: inputHidden ? 'hidden' : 'visible'
            }}
          />
        }
        inputProps={{
          sx: {
            backgroundClip: 'text'
          }
        }}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              ...(height && { height })
            }
          }
        }}
        {...props}
      >
        {search && (
          <MenuItem onKeyDown={moveFocusToInput} key='search-input'>
            <OutlinedInput
              inputRef={filterRef}
              value={searchText}
              fullWidth
              placeholder={t('labels.search')}
              onKeyDown={stopPropagation}
              onChange={(e) => setSearchText(e.target.value)}
              endAdornment={
                <InputAdornment position='end'>
                  <SearchIcon color='disabled' />
                </InputAdornment>
              }
            />
          </MenuItem>
        )}
        {showSelectAll && [
          <MenuItem
            tabIndex={0}
            value={{ id: 'all', name: 'all' } as AnyType}
            key='select-all'
            sx={{
              '&.Mui-selected': {
                bgcolor: 'background.paper'
              }
            }}
          >
            <ListItemIcon>
              <Checkbox tabIndex={-1} checked={allSelected} />
            </ListItemIcon>
            <ListItemText primary={showAllText} />
          </MenuItem>,
          <HrStyled key='debugHR' />
        ]}
        {filtered.map((o, index) => {
          const optionDisabled = disabledOptions.includes(o.id);
          const checked = !!value?.find((v) => v.id === o.id);
          const title = optionDisabled ? optionDisabledInfo : '';

          return (
            <MenuItem
              {...(!showSelectAll && index === 0 && { tabIndex: 0 })}
              key={`multiselect-item-${o.id}`}
              value={o as AnyType}
              data-testid='multiselect-option'
              disabled={optionDisabled}
              sx={{
                '&.Mui-selected': {
                  bgcolor: 'background.paper'
                }
              }}
            >
              <Tooltip title={title} placement='bottom-start'>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <ListItemIcon>
                    <Checkbox
                      {...(!showSelectAll && index === 0 && { tabIndex: -1 })}
                      checked={checked || allSelected}
                      disabled={optionDisabled}
                    />
                  </ListItemIcon>
                  <ListItemText primary={o.name} />
                </Box>
              </Tooltip>
            </MenuItem>
          );
        })}
      </MuiSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default MultiSelect;
