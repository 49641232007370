import { TFunction } from 'i18next';

import { ApiCodedErrorType } from 'Types/index';

export class OrderDialogsHelper {
  static readonly handleValidationException = (
    error: unknown,
    t: TFunction,
    setSubmitErrorMessage: (message: string) => void
  ) => {
    const errorData = (error as { response?: { data: ApiCodedErrorType } })?.response?.data;
    if (errorData?.Message) setSubmitErrorMessage(`${t(errorData.Message)}.`);
  };
}
