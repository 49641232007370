import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { Autocomplete } from '@mui/lab';
import { Checkbox, ListSubheader, TextField, Typography } from '@mui/material';

const SELECT_ALL_OPTION_ID = 'select-all';

type OptionValue = string | number;

type Option<T extends OptionValue> = {
  id: T;
  name: string;
};

const prepareItems = (children: (React.ReactChild & { children?: React.ReactChild[] })[]) => {
  const itemData: React.ReactChild[] = [];
  children.forEach((item, index) => {
    if (index !== 0) itemData.push(item);
    itemData.push(...(item.children ?? []));
  });

  return itemData;
};

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];

  const inlineStyle = {
    ...style,
    top: (style.top as number) + 8
  };

  if (dataSet.children?.length > 0) {
    return (
      <ListSubheader key={dataSet.key} component='div' style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
      <Checkbox checked={dataSet[1].id === SELECT_ALL_OPTION_ID ? dataSet[1].selected : dataSet[2]?.selected} />
      {dataSet[1].name}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(itemCount: number) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [itemCount]);

  return ref;
}

const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = prepareItems(children as React.ReactChild[]);
  const itemCount = itemData.length;
  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={400}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={() => 40}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export type MultiSelectVirtualizedProps<T extends OptionValue> = {
  value?: Option<T>[];
  options: Option<T>[];
  onChange?: (value: Option<T>[]) => void;
  disabled?: boolean;
  name?: string;
  label?: string;
  width?: string | number;
  fullWidth?: boolean;
  height?: string | number;
  disabledOptions?: T[];
  helperText?: string;
  error?: boolean;
  hideTags?: boolean;
  limitTags?: number;
  id?: string;
};

const areEqualSets = <T extends OptionValue>(left: Set<T>, right: Set<T>) =>
  left.size === right.size && [...left].every((x) => right.has(x));

const getSorted = <T extends OptionValue>(options: Option<T>[]) =>
  [...options].sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));

const MultiSelectVirtualized = <T extends OptionValue>({
  options = [],
  value = [],
  onChange = () => {},
  disabled = false,
  name = '',
  label = '',
  helperText = '',
  error = false,
  disabledOptions = [],
  width,
  fullWidth = false,
  height,
  hideTags = false,
  limitTags = 3,
  id
}: MultiSelectVirtualizedProps<T>) => {
  const fieldId = id ?? `${name}-multi-select-virtualized`;
  const { t } = useTranslation();
  const disabledOptionsSet = useMemo(() => new Set(disabledOptions), [disabledOptions]);
  const availableOptionsSet = useMemo(
    () => new Set(options.filter((option) => !disabledOptionsSet.has(option.id)).map(({ id }) => id)),
    [options, disabledOptionsSet]
  );
  const previousSelectedSet = useMemo(() => new Set(value.map(({ id }) => id)), [value]);
  const isEveryOptionSelected = useMemo(
    () => areEqualSets(availableOptionsSet, previousSelectedSet),
    [availableOptionsSet, previousSelectedSet]
  );

  return (
    <Autocomplete
      id={fieldId}
      fullWidth={fullWidth}
      value={value}
      onChange={(_, selectedValues) => {
        if (selectedValues.at(-1)?.id === SELECT_ALL_OPTION_ID) {
          if (!isEveryOptionSelected) {
            onChange(
              options.filter((option) =>
                disabledOptionsSet.has(option.id) ? previousSelectedSet.has(option.id) : true
              )
            );
          } else {
            onChange(
              options.filter((option) => disabledOptionsSet.has(option.id) && previousSelectedSet.has(option.id))
            );
          }
        } else {
          onChange(selectedValues);
        }
      }}
      multiple
      disableListWrap
      disableCloseOnSelect
      disableClearable
      options={[
        { id: SELECT_ALL_OPTION_ID as T, name: `${t('labels.all')}`, selected: isEveryOptionSelected },
        ...getSorted(options)
      ]}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      renderOption={(props, option, state) => [props, option, state] as React.ReactNode}
      ListboxComponent={ListboxComponent}
      limitTags={limitTags}
      getLimitTagsText={(more) => `+${more} ${t('labels.more').toLowerCase()}`}
      groupBy={(option) => option.name[0].toUpperCase()}
      renderGroup={(params) => params as unknown as React.ReactNode}
      disabled={disabled}
      noOptionsText={t('labels.noOptions')}
      getOptionDisabled={(option) => disabledOptions.includes(option.id)}
      {...(hideTags && { renderTags: () => null })}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name={name}
          error={error}
          helperText={helperText}
          maxRows={1}
          placeholder={`${t('labels.search')}...`}
          focused
        />
      )}
      sx={{ width, height }}
    />
  );
};

export default MultiSelectVirtualized;
