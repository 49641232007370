import React from 'react';
import { Box } from '@mui/material';

const PageLayout: React.FC = ({ children }) => (
  <Box height='100%' display='flex' flexDirection='column'>
    {children}
  </Box>
);

export default PageLayout;
