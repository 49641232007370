import React from 'react';
import { AutocompleteProps, Box, BoxProps, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import Typography from 'Components/Typography';
import { AnyType } from 'Types';

export type ChipsMultiSelectProps = {
  containerProps?: BoxProps;
  inputPlaceholder?: string;
  error?: boolean;
  errorText?: string;
} & Omit<AutocompleteProps<AnyType, boolean, boolean, boolean>, 'renderInput'>;

const ChipsMultiSelect = ({ containerProps, inputPlaceholder, error, errorText, ...props }: ChipsMultiSelectProps) => {
  return (
    <Box {...containerProps}>
      <Autocomplete
        popupIcon=''
        disableCloseOnSelect
        multiple
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            placeholder={inputPlaceholder}
            variant='outlined'
            sx={{
              '& .MuiInputBase-root': {
                borderRadius: 4,
                padding: 0.5
              }
            }}
          />
        )}
        sx={{
          '& .MuiChip-root': {
            fontWeight: 500
          }
        }}
        {...props}
      />
      {error && (
        <Box pl={1}>
          <Typography variant='caption' textColor='red'>
            {errorText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
export default ChipsMultiSelect;
