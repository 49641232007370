import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';

import { Bold } from 'Styles/utils';

const EndedOrderAlert = ({ onClose }: { onClose?: () => void }) => {
  const { t } = useTranslation();

  return (
    <Alert severity='warning' onClose={onClose} sx={{ width: 512, boxShadow: 3, zIndex: 2, position: 'sticky' }}>
      {t('messages.theWorkOnTheComponentAtYourWorkstationHasBeenCompleted')}
      {'. '}
      <Trans t={t} i18nKey='messages.leaveOrderAsSoonAsPossible'>
        <Bold>Leave order</Bold> as soon as possible.
      </Trans>
    </Alert>
  );
};

export default EndedOrderAlert;
