import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';

import Typography from 'Components/Typography';

const TimeBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  color: theme.palette.getContrastText(theme.palette.primary.contrastText)
}));

const getWeekNumber = (t: Date) => {
  const d = new Date(Date.UTC(t.getFullYear(), t.getMonth(), t.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);

  return weekNo;
};

export const CurrentTime = ({ collapsed }: { collapsed: boolean }) => {
  const [time, setTime] = useState(new Date());
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <TimeBox
      display='flex'
      alignItems='center'
      justifyContent={collapsed ? 'flex-start' : 'space-between'}
      flexDirection={collapsed ? 'column' : 'row'}
      sx={{
        paddingX: collapsed ? 1 : 3,
        paddingY: collapsed ? 1 : 2,
        width: '100%',
        height: '68px',
        flexShrink: 0
      }}
    >
      <Typography weight='bold' sx={{ fontSize: `${collapsed ? 20 : 32}px !important` }}>
        {`${time.toTimeString().substring(0, 5)}`}
      </Typography>
      <Typography weight='bold' sx={{ fontSize: `${collapsed ? 10 : 12}px !important`, lineHeight: `12px` }}>
        {`${time.getDate()}.${time.getMonth() + 1}.${time.getFullYear()}`}
        <br />
        {`${t('labels.week')} ${getWeekNumber(time)}`}
      </Typography>
    </TimeBox>
  );
};

export default CurrentTime;
