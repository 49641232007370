import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterList from '@mui/icons-material/FilterList';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, IconButton, TextField, TextFieldProps } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';

const FilterOutlined = styled(FilterList, {
  shouldForwardProp: (prop) => prop !== 'focus'
})<{ focus: boolean }>(({ theme, focus }) => ({
  height: 32,
  width: 32,
  backgroundColor: focus ? theme.palette.action.focus : 'transparent',
  borderRadius: '50%',
  padding: 4,
  marginLeft: 23,
  marginBottom: '-5',
  cursor: 'pointer'
}));

export type ColumnTextFilterProps = {
  title: string;
  label: string;
  onChange: TextFieldProps['onChange'];
  onClearClick: () => void;
  value: string | undefined;
  className?: string;
};

export const ColumnTextFilter = ({ title, value, onChange, className, label, onClearClick }: ColumnTextFilterProps) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const hasValue = value && value.length > 0;
  const showFilterInput = open || hasValue;
  const inputRef = useRef<HTMLInputElement>(null);

  const clearSearchString = () => {
    setOpen(true);
    onClearClick();
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Box display='flex' flexDirection='row' alignItems='center' justifyContent='flex-start' height={60}>
      {!showFilterInput ? (
        <>
          {title}
          <FilterOutlined
            focus={false}
            onClick={() => setOpen(true)}
            className={className}
            style={{ marginBottom: '-5' }}
          />
        </>
      ) : (
        <TextField
          inputRef={inputRef}
          onChange={onChange}
          value={value}
          autoFocus
          variant='outlined'
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setOpen(false);
            }
          }}
          onBlur={() => {
            setOpen(false);
          }}
          name={title}
          placeholder={t('labels.search')}
          label={label}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton aria-label='clear search field' onClick={clearSearchString} edge='end'>
                  <HighlightOffIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
    </Box>
  );
};

export default ColumnTextFilter;
