import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Typography } from '@mui/material';

import { DialogActions, DialogTitle } from 'Components/Dialog/Dialog';
import { errorCodes } from 'Consts/Errors';
import getErrorCode from 'Helpers/ApiErrorCodeGetter';
import useDeleteTechnology from 'Hooks/technologies/useDeleteTechnology';
import { useTechnology } from 'Hooks/technologies/useTechnology';
import useFocus from 'Hooks/useFocus';
import { Bold } from 'Styles/utils';

interface Props {
  open: boolean;
  onClose: () => void;
  id?: string;
}

const DeleteDialog: React.FC<Props> = ({ open, onClose, id = '' }) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const { data: technology, isError: isTechnologyError, isLoading: isTechnologyLoading } = useTechnology(id);
  const { mutate: deleteTechnology, isLoading: isTechnologyDeleting } = useDeleteTechnology();
  const focusRef = useFocus();

  const handleDelete = () => {
    deleteTechnology(id, {
      onSuccess: onClose,
      onError: (error) => {
        const errorCode = getErrorCode(error);
        if (errorCode === errorCodes.entityNotFound) {
          setErrorMessage(`${t('messages.technologyHasBeenDeleted')}`);
        }
      }
    });
  };

  if (isTechnologyLoading) return <div />;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose} fontWeight='400'>
        {t('labels.confirmDeletion')}: <Bold>{technology?.name}</Bold>
      </DialogTitle>
      <DialogActions sx={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          ref={focusRef}
          onClick={handleDelete}
          disabled={isTechnologyDeleting || !!errorMessage || isTechnologyError}
        >
          {t('labels.deleteTechnology')}
        </Button>
        {errorMessage && (
          <Typography variant='caption' color='error'>
            {errorMessage}
          </Typography>
        )}
        {isTechnologyError && (
          <Typography variant='caption' color='error'>
            {t('messages.unknownErrorOccurred')}
          </Typography>
        )}
        <Button variant='text' onClick={onClose} sx={{ mt: 1 }}>
          {t('labels.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
