import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';

import { AnyType } from 'Types';

const OptionsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'usingKeyboardFocus'
})<{ usingKeyboardFocus: boolean; readOnly?: boolean }>(({ usingKeyboardFocus, theme, readOnly }) => ({
  position: 'relative',
  display: 'flex',
  border: readOnly ? 'none' : `2px solid ${theme.palette.primary.main}`,
  borderRadius: 25,
  overflow: 'hidden',
  padding: '0px 1px 1px',
  ...(usingKeyboardFocus && {
    '&:focus-within': {
      boxShadow: theme.shadows[6]
    }
  })
}));

const Option = styled('div')<{ selected: boolean; readOnly?: boolean }>(({ selected, readOnly, theme }) => ({
  borderRadius: 20,
  cursor: readOnly ? 'auto' : 'pointer',
  backgroundColor: selected ? theme.palette.primary.main : 'transparent',
  color: selected ? theme.palette.primary.contrastText : theme.palette.text.primary
}));

const OptionLabel = styled('label')<{ padding?: string; readOnly?: boolean }>(
  ({ padding = '6px 25px', readOnly, theme }) => ({
    padding,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: theme.typography.fontFamily,
    textTransform: 'uppercase',
    cursor: readOnly ? 'auto' : 'pointer'
  })
);

const OptionInput = styled('input')({
  width: 0,
  height: 0,
  opacity: 0,
  zIndex: -1,
  position: 'absolute'
});

export interface SwitchSelectorProps {
  options?: { label: string; value: AnyType }[];
  onChange?: (value: AnyType) => void;
  padding?: string;
  indexSelected?: number;
  wrapperStyle?: React.CSSProperties;
  optionStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  readOnly?: boolean;
}

const SwitchSelector: React.FC<SwitchSelectorProps> = ({
  options = [],
  onChange,
  padding,
  indexSelected = 0,
  optionStyle,
  labelStyle,
  wrapperStyle,
  readOnly,
  ...props
}) => {
  const [selectedIndex, setSelectedIndex] = useState(indexSelected);
  const [usingKeyboardFocus, setUsingKeyboardFocus] = useState(false);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.code === 'Tab') {
        setUsingKeyboardFocus(true);
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  const handleOptionClick = (index: number) => {
    if (!readOnly && index !== selectedIndex) {
      setSelectedIndex(index);
      onChange?.(options[index].value);
    }
  };

  useEffect(() => {
    setSelectedIndex(indexSelected);
  }, [indexSelected]);

  const renderOptions = () =>
    options.map((option, index) => {
      const optionId = `option-${index}`;

      if (readOnly && selectedIndex !== index) return null;

      return (
        <Option style={optionStyle} key={optionId} selected={selectedIndex === index} readOnly={readOnly}>
          <OptionLabel style={labelStyle} padding={padding} readOnly={readOnly}>
            <OptionInput
              {...props}
              checked={selectedIndex === index}
              id={optionId}
              type='radio'
              onChange={() => handleOptionClick(index)}
              readOnly={readOnly}
            />
            {option.label}
          </OptionLabel>
        </Option>
      );
    });

  return (
    <OptionsWrapper
      style={wrapperStyle}
      onMouseDown={() => setUsingKeyboardFocus(false)}
      usingKeyboardFocus={usingKeyboardFocus}
      readOnly={readOnly}
    >
      {renderOptions()}
    </OptionsWrapper>
  );
};

export default SwitchSelector;
