import React, { useLayoutEffect } from 'react';
import { isMobile } from 'react-device-detect';

const ResizeHandler: React.FC = ({ children }) => {
  useLayoutEffect(() => {
    if (isMobile) {
      const viewport = document?.querySelector('meta[name=viewport]');
      if (viewport) {
        viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

        const initialWidth = window.innerWidth;
        const initialHeight = window.innerHeight;

        const handleResize = () => {
          if (window.innerWidth === initialWidth) {
            if (window.innerHeight < initialHeight) {
              document.documentElement.style.setProperty('overflow', 'auto');
              viewport.setAttribute('content', `height=${initialHeight}px, width=device-width, initial-scale=1.0`);
            } else {
              document.documentElement.style.setProperty('overflow', 'hidden');
              viewport.setAttribute(
                'content',
                'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
              );
            }
          } else {
            viewport.setAttribute(
              'content',
              `width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0`
            );
          }
        };

        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }
    }

    return () => {};
  }, []);

  return <>{children}</>;
};

export default ResizeHandler;
