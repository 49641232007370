import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';

import { DateRangeFormFilters } from 'Components/DateFilters/DateRangeFormFilters';

const OrdersFilters: FC = () => {
  const { control } = useFormContext();

  return (
    <Box display='flex' width='100%' justifyContent='flex-end' alignItems='center' pb={2}>
      <Controller
        name='dateRange'
        control={control}
        render={({ field: { value, onChange } }) => <DateRangeFormFilters value={value} onChange={onChange} />}
      />
    </Box>
  );
};

export default OrdersFilters;
