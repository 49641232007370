import { differenceInSeconds, format } from 'date-fns';
import i18next from 'i18next';

import { TimePeriodOption } from 'Hooks/productionFilters/ProductionFiltersTypes';
import { ShiftConfigurationElement, ShiftDto, ShiftsConfigurationDto } from 'Types';

const getCurrentShiftTrimmed = (shift: ShiftConfigurationElement, effectiveTo: string) => {
  const effectiveToDate = new Date(effectiveTo);

  return {
    ...shift,
    endHour: effectiveToDate.getHours(),
    endMinute: effectiveToDate.getMinutes()
  };
};

export const getCurrentShift = (shiftsConfiguration: ShiftsConfigurationDto | null) => {
  if (!shiftsConfiguration) {
    return null;
  }

  const date = new Date();
  const currentShiftConfiguration = shiftsConfiguration.elements.find((shift) => isDuringGivenShift(date, shift));

  if (
    currentShiftConfiguration &&
    shiftsConfiguration.effectiveTo &&
    isDuringGivenShift(new Date(shiftsConfiguration.effectiveTo), currentShiftConfiguration)
  ) {
    return getCurrentShiftTrimmed(currentShiftConfiguration, shiftsConfiguration.effectiveTo);
  }

  return currentShiftConfiguration ?? null;
};

export const getTimeToShiftEndInSeconds = (currentShift: ShiftConfigurationElement) => {
  const shiftEnd = getShiftEndDate(currentShift);
  const now = new Date();

  now.setMilliseconds(0);

  return differenceInSeconds(shiftEnd, now);
};

export const isBetweenDaysShift = (shift: ShiftConfigurationElement) =>
  shift.startHour > shift.endHour || (shift.startHour === shift.endHour && shift.startMinute >= shift.endMinute);

export const isTimeBetweenShiftStartAndMidnight = (shift: ShiftConfigurationElement, date: Date) => {
  const currentHour = date.getHours();
  const currentMinute = date.getMinutes();

  return shift.startHour < currentHour || (shift.startHour === currentHour && shift.startMinute <= currentMinute);
};
export const getShiftStartDate = (shift: ShiftConfigurationElement) => {
  const startTime = new Date();
  const isShiftStartedYesterday = isBetweenDaysShift(shift) && !isTimeBetweenShiftStartAndMidnight(shift, startTime);

  if (isShiftStartedYesterday) {
    startTime.setDate(startTime.getDate() - 1);
  }
  startTime.setHours(shift.startHour);
  startTime.setMinutes(shift.startMinute);
  startTime.setSeconds(0);
  startTime.setMilliseconds(0);

  return startTime;
};

export const getShiftEndDate = (shift: ShiftConfigurationElement) => {
  const endTime = new Date();
  const isShiftEndingTheNextDay = isBetweenDaysShift(shift) && isTimeBetweenShiftStartAndMidnight(shift, endTime);

  if (isShiftEndingTheNextDay) {
    endTime.setDate(endTime.getDate() + 1);
  }
  endTime.setHours(shift.endHour);
  endTime.setMinutes(shift.endMinute - 1);
  endTime.setSeconds(59);
  endTime.setMilliseconds(999);

  return endTime;
};

export const isEarlierThanGivenShift = (date: Date, shift: ShiftConfigurationElement) => {
  if (shift && date.getTime() < getShiftStartDate(shift).getTime()) {
    return true;
  }

  return false;
};

export const isLaterThanGivenShift = (date: Date, shift: ShiftConfigurationElement) => {
  if (shift && date.getTime() > getShiftEndDate(shift).getTime()) {
    return true;
  }

  return false;
};

export const isDuringGivenShift = (date: Date, shift: ShiftConfigurationElement) => {
  return !isEarlierThanGivenShift(date, shift) && !isLaterThanGivenShift(date, shift);
};

export const isDateRangeIntersectCurrentShift = (from: string, to: string, shift: ShiftConfigurationElement) => {
  return getShiftStartDate(shift) < new Date(to) && getShiftEndDate(shift) > new Date(from);
};

export const formatShiftToRangeString = (shift: ShiftDto) => {
  const shiftLabel = i18next.t('labels.shift').toUpperCase();
  const hoursFormat = 'HH:mm';
  const from = format(new Date(shift.from), hoursFormat);
  const to = format(new Date(shift.to), hoursFormat);

  return `${shiftLabel} ${shift.name} (${from} - ${to})`;
};

export const formatTimePeriodToRangeString = (timePeriod: TimePeriodOption) => {
  if (timePeriod?.shiftConfigurationElementId) {
    return formatShiftToRangeString(timePeriod as ShiftDto);
  }

  const hoursFormat = 'HH:mm';
  const from = format(new Date(timePeriod.from), hoursFormat);
  const to = format(new Date(timePeriod.to), hoursFormat);

  return `${timePeriod.name} (${from} - ${to})`;
};
