import { useQuery, UseQueryOptions } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { TranslationValuesDto } from 'Types';

const useTranslationValues = (
  languageCode: string,
  queryOptions?: UseQueryOptions<TranslationValuesDto, unknown, TranslationValuesDto, ['translation-values', string]>
) =>
  useQuery(
    ['translation-values', languageCode],
    async () => {
      const { data } = await apiClient.getTranslationValues(languageCode);

      return data;
    },
    queryOptions
  );

export default useTranslationValues;
