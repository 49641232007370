import { useQuery, UseQueryOptions } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { GetActiveMachinesApiResponse } from 'Types';

export const useActiveMachines = (
  options?: UseQueryOptions<GetActiveMachinesApiResponse, unknown, GetActiveMachinesApiResponse, ['active-machines']>
) => {
  return useQuery(
    ['active-machines'],
    async () => {
      const { data } = await apiClient.getActiveMachines();

      return data;
    },
    {
      ...options
    }
  );
};
