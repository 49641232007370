import React, { createContext, useContext, useMemo, useState } from 'react';

interface SnackbarsContextInterface {
  connectedSnackbarOpen: boolean;
  setConnectedSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  disconnectedSnackbarOpen: boolean;
  setDisconnectedSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SnackbarsContext = createContext<SnackbarsContextInterface>(undefined!);

export const useSnackbar = (): SnackbarsContextInterface => useContext(SnackbarsContext);

export const SnackbarsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [connectedSnackbarOpen, setConnectedSnackbarOpen] = useState(false);
  const [disconnectedSnackbarOpen, setDisconnectedSnackbarOpen] = useState(false);

  const value = useMemo(
    () => ({
      connectedSnackbarOpen,
      setConnectedSnackbarOpen,
      setDisconnectedSnackbarOpen,
      disconnectedSnackbarOpen
    }),
    [connectedSnackbarOpen, disconnectedSnackbarOpen]
  );

  return <SnackbarsContext.Provider value={value}>{children}</SnackbarsContext.Provider>;
};
