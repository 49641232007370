import { useQuery, UseQueryOptions } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { OrdersImportPreValidationDto, PostOrdersImportPreValidationDto } from 'Types';

export const useOrdersImportPreValidation = (
  orders: PostOrdersImportPreValidationDto,
  options?: UseQueryOptions<
    OrdersImportPreValidationDto[],
    unknown,
    OrdersImportPreValidationDto[],
    ['import-orders-pre-validation']
  >
) =>
  useQuery(
    ['import-orders-pre-validation'],
    async () => {
      const { data } = await apiClient.importOrdersPreValidation(orders);

      return data;
    },
    options
  );
