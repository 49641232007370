import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { endOfDay, isToday, isYesterday, startOfDay, subDays } from 'date-fns';
import { Button } from 'devextreme-react/button';

import { DXDateRange } from 'Components/DXDateRange/DXDateRange';
import theme from 'Theme/theme';

type Props = {
  onDateRangeChange: ([startDate, endDate]: [Date, Date]) => void;
  selectedDateRange: [Date, Date];
  onTodayClick?: () => void;
  onYesterdayClick?: () => void;
  minDate?: Date;
  maxDate?: Date;
};

const DateRangeFilters: FC<Props> = ({ onDateRangeChange, selectedDateRange, minDate, maxDate }: Props) => {
  const { t } = useTranslation();
  const isYesterdaySelected = isYesterday(selectedDateRange[0]) && isYesterday(selectedDateRange[1]);
  const isTodaySelected = isToday(selectedDateRange[0]) && isToday(selectedDateRange[1]);

  const handleTodayClick = () => onDateRangeChange([startOfDay(new Date()), endOfDay(new Date())]);

  const handleYesterdayClick = () =>
    onDateRangeChange([startOfDay(subDays(new Date(), 1)), endOfDay(subDays(new Date(), 1))]);

  return (
    <Box
      display='flex'
      alignItems='flex-end'
      sx={{
        justifyContent: { xs: 'space-between', md: 'flex-end' },
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'flex-start', sm: 'flex-end' }
      }}
    >
      <Box>
        <DXDateRange
          selectedDateRange={selectedDateRange}
          onDateRangeChange={onDateRangeChange}
          minDate={minDate}
          maxDate={maxDate}
          height={45}
        />
      </Box>
      <Box display='flex' sx={{ marginTop: { xs: theme.spacing(1), sm: 0 } }}>
        <Box sx={{ marginLeft: { sm: theme.spacing(2) } }}>
          <Button
            text={t('labels.yesterday')}
            onClick={handleYesterdayClick}
            stylingMode={isYesterdaySelected ? 'contained' : 'outlined'}
            height={45}
            type='default'
          />
        </Box>
        <Box sx={{ marginLeft: theme.spacing(2) }}>
          <Button
            text={t('labels.today')}
            onClick={handleTodayClick}
            stylingMode={isTodaySelected ? 'contained' : 'outlined'}
            height={45}
            type='default'
          />
        </Box>
      </Box>
    </Box>
  );
};

export default DateRangeFilters;
