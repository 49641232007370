import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AuthManager, { AuthManagerErrors } from 'Auth/AuthManager';
import HubMethods from 'Consts/HubMethods';
import { LocalStorageKeys } from 'Consts/LocalStorageKeys';
import { useDialogsContext } from 'Context/DialogsContext';
import { useMachineContext } from 'Context/MachineContext';
import { useServiceWorker } from 'Context/ServiceWorkerContext';
import { useSignalRContext } from 'Context/SignalRContext';
import { useUser as useUserContext } from 'Context/UserContext';
import EndWorkDialog from 'Layout/Modals/EndWorkDialog';
import paths from 'Routes/paths';
import { DashboardType, type RfidCardAppliedMessage } from 'Types';

import CardRemoved from './CardRemoved';
import UpgradeDialog from './UpgradeDialog';

const GlobalPopupsHandler = () => {
  const { user } = useUserContext();
  const { machine } = useMachineContext();
  const { hubConnection, connectedClientId } = useSignalRContext();
  const {
    cardRemovedOpen,
    setCardRemovedOpen,
    removeCardDialogOpen,
    addMultiOperatorDialogOpen,
    replaceMultiOperatorDialogOpen,
    setRemoveCardDialogOpen,
    closeAll
  } = useDialogsContext();
  const { newVersion, updateApplicationAndReloadPage } = useServiceWorker();
  const location = useLocation();
  const history = useHistory();
  const isLoginPage = [paths.rfidLogin, paths.login].includes(location.pathname);
  const machineId = machine?.id;

  useEffect(() => {
    if (!machine?.active) {
      closeAll();
    }
  }, [machine]);

  const enableRfidCardAppliedListener =
    !isLoginPage &&
    !removeCardDialogOpen &&
    !cardRemovedOpen &&
    !addMultiOperatorDialogOpen &&
    !replaceMultiOperatorDialogOpen;

  const enableRfidCardAppliedListenerOnSimpleDashboard =
    machine &&
    machine?.dashboardType !== DashboardType.Normal &&
    machine.isFastLoginEnabled &&
    machine.isAutoLogoutOnOtherRfidCardScanEnabled;

  useEffect(() => {
    const handleRfidCardApplied = ({ isValid, rfid, workstationId }: RfidCardAppliedMessage) => {
      if (isValid && user && user.rfid === rfid && machineId === workstationId) {
        setCardRemovedOpen(true);
      }
    };

    const rfidLogin = (rfid: string, workstationId: string) => {
      if (machineId !== workstationId || !connectedClientId) return;
      AuthManager.rfidLogin(machineId, connectedClientId, rfid)
        .then(() => {
          history.push(paths.operatorDashboard);
        })
        .catch((error) => {
          if (error?.message !== AuthManagerErrors.REQUEST_IN_PROGRESS) {
            AuthManager.logout();
          }
        });
    };

    const handleOtherOperatorRfidCardApplied = ({ isValid, rfid, workstationId }: RfidCardAppliedMessage) => {
      if (isValid && user && user.rfid !== rfid && machineId === workstationId && connectedClientId) {
        AuthManager.logout().then(() => {
          localStorage.removeItem(LocalStorageKeys.REDIRECT_PATH);
          rfidLogin(rfid, workstationId);
        });
      }
    };

    if (enableRfidCardAppliedListener) {
      hubConnection?.on(HubMethods.RfidCardApplied, handleRfidCardApplied);
    }

    if (enableRfidCardAppliedListenerOnSimpleDashboard) {
      hubConnection?.on(HubMethods.MultiOperatorCardApplied, handleOtherOperatorRfidCardApplied);
    }

    return () => {
      if (enableRfidCardAppliedListener) {
        hubConnection?.off(HubMethods.RfidCardApplied, handleRfidCardApplied);
      }
      if (enableRfidCardAppliedListenerOnSimpleDashboard) {
        hubConnection?.off(HubMethods.MultiOperatorCardApplied, handleOtherOperatorRfidCardApplied);
      }
    };
  }, [hubConnection, user, enableRfidCardAppliedListener, machineId]);

  useEffect(() => {
    if (!user) {
      setCardRemovedOpen(false);
    }
  }, [user]);

  return (
    <>
      {removeCardDialogOpen && (
        <EndWorkDialog open={removeCardDialogOpen} onClose={() => setRemoveCardDialogOpen(false)} />
      )}
      {!removeCardDialogOpen && cardRemovedOpen && (
        <CardRemoved open={cardRemovedOpen} close={() => setCardRemovedOpen(false)} />
      )}
      {newVersion && <UpgradeDialog open={newVersion} onSubmit={() => updateApplicationAndReloadPage()} />}
    </>
  );
};

export default GlobalPopupsHandler;
