import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { Box, CircularProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import {
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  TableOptions,
  useReactTable
} from '@tanstack/react-table';

import { SizeMapCacheProvider } from 'Components/SizeMapCache/SizeMapCacheContext';
import ZeroState from 'Components/ZeroState';

import ReactTableVirtualizedVariableSizeRow from './ReactTableVirtualizedRow';
import TableHeaderCell from './TableHeaderCell';

const getScrollbarWidth = () => {
  const scrollDiv = document.createElement('div');
  scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;');
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  return scrollbarWidth;
};

type Props<T extends Record<string, unknown>> = {
  data: T[];
  columns: TableOptions<T>['columns'];
  enableExpanding?: boolean;
  defaultExpanded?: boolean;
  fetchNextPage?: () => void;
  renderRow?: (row: Row<T>, index: number) => React.ReactNode;
  renderNoDataMessage?: () => React.ReactNode;
  isLoading?: boolean;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  pageSize?: number;
};

export const ReactTableVirtualized = <T extends Record<string, unknown>>({
  columns,
  data,
  isLoading,
  enableExpanding = false,
  defaultExpanded = false,
  renderRow,
  renderNoDataMessage,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
  pageSize
}: Props<T>) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<ExpandedState>(defaultExpanded || {});
  const { getHeaderGroups, getRowModel, getTotalSize } = useReactTable<T>({
    columns,
    data,
    getSubRows: (row) => row.subRows as T[],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    enableExpanding,
    state: {
      expanded
    }
  });
  const scrollBarWidth = useMemo(() => getScrollbarWidth(), []);

  const fetchMoreData = useCallback(() => {
    const isMoreDataAvailable =
      hasNextPage && data.length > 0 && pageSize !== undefined ? data.length % pageSize === 0 : true;
    if (isMoreDataAvailable && !isFetchingNextPage) {
      fetchNextPage?.();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage, data.length, pageSize]);

  return (
    <Paper sx={{ height: '100%', width: '100%' }}>
      <TableContainer sx={{ display: 'flex', height: '100%', width: '100%', borderRadius: 4, overflowY: 'hidden' }}>
        <Table stickyHeader aria-label='sticky table' component='div'>
          <TableHead sx={{ position: 'sticky', top: 0 }} component='div'>
            {getHeaderGroups().map((headerGroup) => {
              return (
                <MuiTableRow
                  key={headerGroup.id}
                  hover={false}
                  selected={false}
                  component='div'
                  sx={{ display: 'flex', flex: '1 0 auto', minWidth: getTotalSize() }}
                >
                  {headerGroup.headers.map((header) => (
                    <TableHeaderCell
                      key={header.id}
                      colSpan={header.colSpan}
                      component='div'
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: header.getSize(),
                        minWidth: header.getSize(),
                        flex: `${header.getSize()} 0 auto`
                      }}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHeaderCell>
                  ))}
                  <TableHeaderCell component='div' style={{ width: `${scrollBarWidth}px`, padding: 0, margin: 0 }} />
                </MuiTableRow>
              );
            })}
          </TableHead>
          <TableBody component='div' sx={{ position: 'relative' }}>
            {isLoading ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)'
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <AutoSizer>
                {({ height = 0, width = 0 }) => {
                  return (
                    <SizeMapCacheProvider defaultItemSize={60}>
                      {({ getItemSize, setListRef }) => {
                        const rows = getRowModel().rows;

                        if (!rows.length) {
                          return (
                            <Box
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)'
                              }}
                            >
                              {renderNoDataMessage ? (
                                renderNoDataMessage()
                              ) : (
                                <ZeroState primaryMessage={t('messages.noDataToBeDisplayed')} />
                              )}
                            </Box>
                          );
                        }

                        return (
                          <VariableSizeList
                            ref={setListRef}
                            style={{ overflowX: 'hidden', scrollbarGutter: 'stable' }}
                            height={height}
                            width={width}
                            itemCount={hasNextPage ? rows.length + 1 : rows.length}
                            itemSize={getItemSize}
                            overscanCount={5}
                          >
                            {(virtualizedRowProps) => {
                              if (hasNextPage && virtualizedRowProps.index === rows.length) {
                                return (
                                  <div key='next-page-trigger' {...virtualizedRowProps}>
                                    <FetchNextPageTrigger fetchMoreData={fetchMoreData} />
                                  </div>
                                );
                              }

                              return (
                                <ReactTableVirtualizedVariableSizeRow
                                  {...virtualizedRowProps}
                                  rows={rows as Row<Record<string, unknown>>[]}
                                  renderRow={renderRow as (row: Row<Record<string, unknown>>, index: number) => void}
                                  minWidth={getTotalSize()}
                                  expandableTable={enableExpanding}
                                />
                              );
                            }}
                          </VariableSizeList>
                        );
                      }}
                    </SizeMapCacheProvider>
                  );
                }}
              </AutoSizer>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const FetchNextPageTrigger = ({ fetchMoreData }: { fetchMoreData: () => void }) => {
  useEffect(() => {
    fetchMoreData();
  }, [fetchMoreData]);

  return (
    <Box display='flex' justifyContent='center'>
      <CircularProgress />
    </Box>
  );
};

export default ReactTableVirtualized;
