import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import type { AnyType } from 'Types';

import SideNavItem from './SideNavItem';

export type NavLinkItem = {
  link: string;
  Icon: AnyType;
  showNotification?: boolean;
  label?: string;
  badge?: number;
  border?: boolean;
  disabled?: boolean;
};

const NavLinks = ({
  items,
  disabled: isNavDisabled,
  collapsed
}: {
  items: NavLinkItem[];
  disabled?: boolean;
  collapsed?: boolean;
}) => {
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <>
      {items.map(({ link, Icon, label, showNotification, border, badge, disabled: isItemDisabled }) => {
        return (
          <SideNavItem
            key={link}
            onClick={() => history.push(link)}
            selected={pathname === link}
            Icon={Icon}
            badge={badge || showNotification}
            disabled={isNavDisabled || isItemDisabled}
            label={label}
            border={border ? 'bottom' : undefined}
            collapsed={collapsed}
          />
        );
      })}
    </>
  );
};

export default NavLinks;
