import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as serviceWorker from 'serviceWorkerRegistration';

interface ServiceWorkerContext {
  updateApplicationAndReloadPage: () => void;
  newVersion: boolean;
}
const defaultValue = { newVersion: false, updateApplicationAndReloadPage: () => {} };

const ServiceWorkerContext = createContext<ServiceWorkerContext>(defaultValue);

export const useServiceWorker = () => useContext(ServiceWorkerContext);

export const ServiceWorkerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [newVersion, setNewVersion] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

  const onUpdateFound = (registration: ServiceWorkerRegistration) => {
    setNewVersion(true);
    setWaitingWorker(registration.waiting);
  };

  const onRegistrationInit = (registration: ServiceWorkerRegistration) => {
    if (registration.waiting) {
      onUpdateFound(registration);
    }
  };

  useEffect(() => {
    serviceWorker.register({
      onUpdate: onUpdateFound,
      onInit: onRegistrationInit
    });
  }, []);

  useEffect(() => {
    if (waitingWorker) {
      waitingWorker.addEventListener('statechange', (e: Event) => {
        // @ts-ignore
        if (e?.target?.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }, [waitingWorker]);

  const updateApplicationAndReloadPage = useCallback(() => {
    if (waitingWorker) {
      setNewVersion(false);
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  }, [waitingWorker]);

  const value = useMemo(
    () => ({ updateApplicationAndReloadPage, newVersion }),
    [newVersion, updateApplicationAndReloadPage]
  );

  return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>;
};
