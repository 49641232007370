import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Typography } from '@mui/material';

import CenteredContentWithLogo from 'Layout/CenteredContentWithLogo';
import PaperCard, { PaperCardContent, PaperCardHeader } from 'Layout/PaperCard';

type Props = {
  Icon: React.ElementType;
  header: string;
  description?: string;
  backBy?: number;
  onClick?: () => void;
  buttonText?: string;
  hideArrow?: boolean;
  hideButton?: boolean;
};

const ErrorPageTemplate: React.FC<Props> = ({
  Icon,
  header,
  description = '',
  backBy = 1,
  onClick,
  buttonText,
  hideArrow = false,
  hideButton = false
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      history.go(-backBy);
    }
  };

  return (
    <CenteredContentWithLogo>
      <PaperCard>
        <Icon sx={{ fontSize: 48 }} />
        <PaperCardHeader>
          <Typography variant='h5' align='center' fontWeight='medium' gutterBottom>
            {header}
          </Typography>
          <Typography align='center'>{description}</Typography>
        </PaperCardHeader>
        <PaperCardContent>
          {!hideButton && (
            <Button onClick={handleClick} variant='text'>
              {hideArrow && <ArrowBackIcon style={{ marginRight: 8 }} />}
              {buttonText ?? t('labels.back')}
            </Button>
          )}
        </PaperCardContent>
      </PaperCard>
    </CenteredContentWithLogo>
  );
};

export default ErrorPageTemplate;
