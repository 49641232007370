import { useMutation } from 'react-query';

import { apiClient } from 'Api/ApiClient';
import { queryClient } from 'Lib/react-query';
import { PostTechnologyDto } from 'Types';

const useAddTechnology = () =>
  useMutation(
    async (data: PostTechnologyDto) => {
      await apiClient.addTechnology(data);
    },
    {
      onSuccess: () => queryClient.invalidateQueries('technologist-technologies')
    }
  );

export default useAddTechnology;
