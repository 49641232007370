import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ExitToApp as LogoutIcon, LockReset } from '@mui/icons-material';

import useLogout from 'Hooks/useLogout';
import paths from 'Routes/paths';

import SideNavItem from './SideNavItem';

type Props = {
  collapsed?: boolean;
};

const LogoutMenuItem: React.FC<Props> = ({ collapsed }: Props) => {
  const { handleLogout, isLoggedIn } = useLogout();
  const { t } = useTranslation();
  const history = useHistory();

  if (!isLoggedIn) {
    return null;
  }

  return (
    <>
      <SideNavItem
        collapsed={collapsed}
        border='top'
        onClick={() => history.push(paths.resetPasswordByOldPassword)}
        Icon={LockReset}
        label={t('labels.changePassword')}
      />
      <SideNavItem
        collapsed={collapsed}
        border='top'
        onClick={handleLogout}
        Icon={LogoutIcon}
        label={t('labels.logout')}
      />
    </>
  );
};

export default LogoutMenuItem;
