import { nanoid } from 'nanoid';

import { PostTechnologyDto, PutTechnologyDto, TechnologyDto } from 'Types/Technologies';

import { TechnologyFormValues } from './validation';

export const mapTechnologyFormValuesToPostTechnologyDto = (values: TechnologyFormValues): PostTechnologyDto => {
  return {
    name: values.name,
    isLineMode: values.isLineMode,
    standardBottleneckTakt:
      values.isLineMode && values.standardBottleneckTakt ? values.standardBottleneckTakt : undefined,
    standardFTQ: values.isLineMode && values.standardFTQ ? values.standardFTQ : undefined,
    components: values.components.map(({ variantId, amount, machines }) => ({
      variantId,
      amount,
      machineIds: machines.map(({ id }) => id)
    }))
  };
};

export const mapTechnologyFormValuesToPutTechnologyDto = (values: TechnologyFormValues): PutTechnologyDto =>
  mapTechnologyFormValuesToPostTechnologyDto(values);

export const mapTechnologyDtoToTechnologyFormValues = (technology: TechnologyDto): TechnologyFormValues => ({
  name: technology.name,
  isLineMode: technology.isLineMode,
  standardBottleneckTakt: technology.standardBottleneckTakt ?? undefined,
  standardFTQ: technology.standardFTQ ?? undefined,
  components: technology.components.map(({ id, name, variantId, amount, technologyComponentMachines }) => ({
    id,
    name,
    variantId,
    amount,
    machines: technologyComponentMachines,
    tempId: nanoid()
  }))
});
