import React from 'react';
import { Close as CloseIcon, KeyboardBackspace as KeyboardBackspaceIcon } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Warning';
import {
  Box,
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  IconButton,
  Stack,
  styled,
  Typography
} from '@mui/material';

const PADDING_SPACING_X = 7;

export interface DialogTitleProps extends MuiDialogTitleProps {
  onClose?: () => void;
  onBack?: () => void;
  disableTypography?: boolean;
}

const DialogTitleStyled = styled(MuiDialogTitle)(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingRight: theme.spacing(PADDING_SPACING_X),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(PADDING_SPACING_X)
}));

export const DialogTitle: React.FC<DialogTitleProps> = (props) => {
  const { children, onClose, disableTypography, onBack, ...other } = props;

  return (
    <>
      <DialogTitleStyled {...other}>
        {disableTypography ? (
          children
        ) : (
          <Typography variant='h5' fontWeight='bold'>
            {children}
          </Typography>
        )}
      </DialogTitleStyled>
      {onBack && (
        <IconButton
          sx={{ width: '40px', height: '40px', position: 'absolute', top: '6px', left: '6px', color: 'text.primary' }}
          aria-label='icon-button-back'
          onClick={onBack}
          size='small'
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      )}
      {onClose && (
        <IconButton
          sx={{ width: '40px', height: '40px', position: 'absolute', top: '6px', right: '6px', color: 'text.primary' }}
          aria-label='icon-button-close'
          onClick={onClose}
          size='small'
        >
          <CloseIcon />
        </IconButton>
      )}
    </>
  );
};

export const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(PADDING_SPACING_X)}`
}));

const MuiDialogActionsStyled = styled(MuiDialogActions)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(PADDING_SPACING_X)}`
}));

type ActionVariantProp = 'space-between' | 'center';

type DialogActionsProps = MuiDialogActionsProps & {
  errorMessage?: string | null;
  actionsVariant?: ActionVariantProp;
};

export const DialogActions: React.FC<DialogActionsProps> = ({
  children,
  actionsVariant = 'space-between',
  errorMessage,
  ...other
}) => {
  return (
    <MuiDialogActionsStyled {...other}>
      <ActionsWithMessage actionsVariant={actionsVariant} errorMessage={errorMessage}>
        {children}
      </ActionsWithMessage>
    </MuiDialogActionsStyled>
  );
};

const ActionsWithMessage: React.FC<{
  errorMessage?: string | null;
  actionsVariant?: ActionVariantProp;
}> = ({ children, actionsVariant, errorMessage }) => {
  return (
    <Stack spacing={2} flex={1}>
      <Stack spacing={2} direction='row' justifyContent={actionsVariant}>
        {children}
      </Stack>
      {errorMessage && (
        <Box sx={{ minWidth: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography variant='body2' color='error' textAlign='center' sx={{ maxWidth: '45ch' }}>
            <ErrorIcon
              fontSize='inherit'
              color='inherit'
              sx={{
                verticalAlign: 'middle',
                mr: 0.5,
                mt: -0.5
              }}
            />
            {errorMessage}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
