import React, { FC, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import { nanoid } from 'nanoid';

import {
  FieldArrayAddItemButton,
  FieldArrayHeader,
  FieldArraySection,
  FormTextField
} from 'Components/form-components';
import { DXCheckbox } from 'Components/form-components/form-inputs/DXCheckbox';
import { NumberInput } from 'Components/form-components/form-inputs/NumberInput';
import theme from 'Theme/theme';

import { TechnologyComponent } from './FormTechnologyComponent';
import { TechnologyFormValues } from './validation';

export interface VariantWithMachines {
  id: string;
  name: string;
  machines: { id: string; name: string; isActive: boolean; isDeleted: boolean }[];
  isActive: boolean;
  isDeleted: boolean;
}

interface TechnologyFormProps {
  variantsWithMachines: VariantWithMachines[];
  containerRef?: React.RefObject<HTMLDivElement | null>;
}

export const TechnologyForm: FC<TechnologyFormProps> = ({ variantsWithMachines, containerRef }) => {
  const { t } = useTranslation();
  const [triggerScrollBottom, setTriggerScrollBottom] = useState(false);

  const { control, trigger, watch } = useFormContext<TechnologyFormValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'components',
    keyName: 'tempId'
  });
  const isLineModeActive = watch('isLineMode');

  useEffect(() => {
    const scrollToBottom = () => {
      if (containerRef?.current) {
        containerRef?.current?.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' });
        setTriggerScrollBottom(false);
      }
    };

    if (triggerScrollBottom) {
      scrollToBottom();
    }
  }, [triggerScrollBottom]);

  const selectedVariantIds = fields.map(({ variantId }) => variantId);

  return (
    <Stack spacing={3}>
      <FormTextField name='name' label={t('labels.name')} fullWidth />
      <DXCheckbox name='isLineMode' label={t('labels.lineMode')} />
      {isLineModeActive ? (
        <Box>
          <NumberInput
            name='standardFTQ'
            label={`${t('labels.standardFTQ')} [s]`}
            style={{ marginBottom: theme.spacing(3) }}
          />
          <NumberInput name='standardBottleneckTakt' label={`${t('labels.standardBottleneckTakt')} [s]`} />
        </Box>
      ) : null}
      <FieldArraySection>
        <FieldArrayHeader
          title={t('labels.technologyComponents')}
          subtitle={t(
            'messages.defineTheComponentsSpecificToThisTechnologyTheirNumberAndSelectTheWorkstationsResponsibleByDefaultForTheProductionOfAGivenComponent'
          )}
        />
        {fields.map((item, index) => {
          const variantsWithMachinesOptions = variantsWithMachines.filter(
            ({ id }) => !selectedVariantIds.includes(id) || id === item.variantId
          );

          return (
            <TechnologyComponent
              key={item.id ?? item.tempId}
              index={index}
              component={item}
              variantsWithMachines={variantsWithMachinesOptions}
              showRemoveButton={fields.length > 1}
              onRemoveClick={() => {
                if (fields.length > 1) {
                  remove(index);
                  trigger('components');
                }
              }}
              defaultExpanded={!item.id}
            />
          );
        })}
        <FieldArrayAddItemButton
          onClick={() => {
            append({ variantId: '', amount: 1, machines: [], tempId: nanoid() });
            setTriggerScrollBottom(true);
          }}
        >
          {t('labels.addComponent')}
        </FieldArrayAddItemButton>
      </FieldArraySection>
    </Stack>
  );
};
