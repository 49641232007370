import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosRequestConfig } from 'axios';

import { ApiAxiosInstance } from 'Api/ApiClient';
import { ApiHeaders } from 'Api/AxiosInstance';
import AuthManager, { AuthEvents } from 'Auth/AuthManager';
import { AppNamesType } from 'Consts/AppNames';
import Analytics from 'Lib/Analytics';
import { queryClient } from 'Lib/react-query';
import { AppUser } from 'Types';

interface UserContextInterface {
  user: AppUser | null;
  selectedApp: AppNamesType;
  setSelectedApp: (data: AppNamesType) => void;
  isOperatorWorkInProgress: boolean;
  setIsOperatorWorkInProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserContext = createContext<UserContextInterface | undefined>(undefined);

export const useUser = (): UserContextInterface => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be within UserContextProvider');
  }

  return context;
};

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<AppUser>(AuthManager.getUser() as AppUser);
  const [selectedApp, _setSelectedApp] = useState<AppNamesType>('');
  const [isOperatorWorkInProgress, setIsOperatorWorkInProgress] = useState(false);
  const selectedAppRef = useRef(selectedApp);

  useEffect(() => {
    const originAppHeaderInterceptor = ApiAxiosInstance.interceptors.request.use(
      (configuration: AxiosRequestConfig) => {
        return {
          ...configuration,
          headers: {
            [ApiHeaders.ORIGIN_APP]: selectedAppRef.current,
            ...configuration.headers
          }
        };
      }
    );

    return () => {
      ApiAxiosInstance.interceptors.request.eject(originAppHeaderInterceptor);
    };
  }, []);

  const setSelectedApp = (appName: AppNamesType) => {
    if (selectedAppRef.current !== appName) {
      queryClient.clear();
      selectedAppRef.current = appName;
      _setSelectedApp(appName);
    }
  };

  useEffect(() => {
    const handleLoadUser = (userData: AppUser) => {
      setUser(userData);
    };
    AuthManager.events.on(AuthEvents.UserLoaded, handleLoadUser);
    AuthManager.events.on(AuthEvents.UserUnloaded, handleLoadUser);

    return () => {
      AuthManager.events.off(AuthEvents.UserLoaded, handleLoadUser);
      AuthManager.events.off(AuthEvents.UserUnloaded, handleLoadUser);
    };
  }, []);

  useEffect(() => {
    if (selectedApp) {
      Analytics.track.panelOpened({ selectedApp });
    }

    return () => {
      if (selectedApp) {
        Analytics.track.panelClosed({ selectedApp });
      }
    };
  }, [selectedApp]);

  const value: UserContextInterface = useMemo(
    () => ({
      user,
      selectedApp,
      setSelectedApp,
      isOperatorWorkInProgress,
      setIsOperatorWorkInProgress
    }),
    [user, selectedApp, isOperatorWorkInProgress]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
