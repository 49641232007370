import React from 'react';
import { useLocation } from 'react-router-dom';

import { useUser as useUserContext } from 'Context/UserContext';
import paths from 'Routes/paths';

import SideNav from './SideNav';

const pathsToHideSidebar = [
  paths.notFound,
  paths.signinCallback,
  paths.machineDeactivated,
  paths.machineRemoved,
  paths.machineAlreadyPaired,
  paths.login,
  paths.rfidLogin,
  paths.root,
  paths.forbidden,
  paths.activateAccount,
  paths.activationLinkExpired,
  paths.activationLinkIncorrect,
  paths.passwordResetLinkExpired,
  paths.passwordResetLinkIncorrect,
  paths.resetPassword,
  paths.forgotPassword,
  paths.home,
  paths.accessDenied,
  paths.workstationDisconnected
];

const isValidApplicationPathname = (pathname: string) => Object.values(paths).includes(pathname);

const isSidebarHiddenForPath = (pathname: string) => {
  return !isValidApplicationPathname(pathname) || pathsToHideSidebar.includes(pathname);
};

const Sidebar = () => {
  const { pathname } = useLocation();
  const { selectedApp } = useUserContext();

  if (isSidebarHiddenForPath(pathname) || !selectedApp) {
    // TODO: think how to better handle paths with /{id}
    if (!pathname.includes(paths.mechanicTaskDetails)) {
      return null;
    }
  }

  return <SideNav />;
};

export default Sidebar;
