import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { styled, TextField } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';

import { AnyType } from 'Types';

type OptionValue = string | number;

type Option<T extends OptionValue> = {
  id: T;
  name: string;
};

export type SelectVirtualizedProps<T extends OptionValue> = {
  value?: T | null;
  options: Option<T>[];
  onChange?: (value?: T | null) => void;
  onClick?: () => void;
  disabled?: boolean;
  name?: string;
  label?: string;
  helperText?: string;
  error?: boolean;
  selectClassNames?: string;
  autoFocus?: boolean;
  fullWidth?: boolean;
  readOnly?: boolean;
  style?: React.CSSProperties;
  id?: string;
};

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  };

  if (dataSet.children?.length > 0) {
    return (
      <>
        <ListSubheader key={dataSet.key} component='div' style={inlineStyle}>
          {dataSet.group}
        </ListSubheader>
      </>
    );
  }

  return (
    <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1].name}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);

  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: AnyType) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);

  return ref;
}

const prepareItem = (children: (React.ReactChild & { children?: React.ReactChild[] })[]): React.ReactChild[] => {
  const result: React.ReactChild[] = [];

  children.forEach((item) => {
    result.push(item);
    result.push(...(item.children ?? []));
  });

  return result;
};

const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = prepareItem(children as React.ReactChild[]);

  const itemCount = itemData.length;
  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={400}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={() => 40}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
});

const getSorted = <T extends { name: string }>(options: T[]) =>
  [...options].sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));

export const SelectVirtualized = <T extends OptionValue>({
  value,
  options = [],
  onChange = () => {},
  onClick = () => {},
  disabled = false,
  name,
  label = '',
  helperText = '',
  error = false,
  selectClassNames,
  autoFocus,
  fullWidth,
  readOnly,
  style,
  id
}: SelectVirtualizedProps<T>) => {
  const fieldId = id ?? `${name}-select-virtualized`;
  const { t } = useTranslation();
  const sortedOptions = useMemo(() => getSorted(options), [options]);
  const selectedOption = useMemo(() => sortedOptions.find((option) => option.id === value), [sortedOptions, value]);

  return (
    <Autocomplete
      disabled={disabled}
      value={selectedOption ?? null}
      readOnly={readOnly}
      onClick={onClick}
      onChange={(_, selectedValue) => {
        onChange(selectedValue?.id);
      }}
      className={selectClassNames}
      id={fieldId}
      disableListWrap
      PopperComponent={StyledPopper}
      fullWidth={fullWidth}
      getOptionLabel={(option) => option.name}
      ListboxComponent={ListboxComponent}
      options={sortedOptions}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      groupBy={(option) => option.name[0].toUpperCase()}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error}
          autoFocus={autoFocus}
          helperText={helperText || ''}
          className={selectClassNames}
          style={style}
          placeholder={`${t('labels.search')}...`}
        />
      )}
      renderOption={(optionProps, option, state) => [optionProps, option, state] as React.ReactNode}
      renderGroup={(params) => params as unknown as React.ReactNode}
    />
  );
};

export default SelectVirtualized;
