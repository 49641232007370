import { Components, Theme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    dark: true;
    blue: true;
    notWorking: true;
    normalWork: true;
    workWithFailure: true;
    failure: true;
    idle: true;
    microidle: true;
    accident: true;
    scrap: true;
    rework: true;
  }
}

const ButtonOverride: Components<Omit<Theme, 'components'>>['MuiButton'] = {
  defaultProps: {
    size: 'large',
    color: 'primary',
    variant: 'contained',
    disableRipple: true
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      ...((ownerState.variant === 'contained' || ownerState.variant === 'outlined') && {
        textTransform: 'none',
        whiteSpace: 'nowrap',
        boxShadow: theme.shadows[4],

        ...(ownerState.size === 'large' && {
          borderRadius: 16,
          fontSize: theme.typography.h6.fontSize,
          minWidth: '200px',

          [theme.breakpoints.up('xxl')]: {
            borderRadius: 18
          }
        }),

        ...(ownerState.size === 'medium' && {
          fontSize: theme.typography.body2.fontSize,
          borderRadius: 12,
          fontWeight: '600',
          padding: '8px 20px',

          [theme.breakpoints.up('xxl')]: {
            borderRadius: 14
          }
        }),

        ...(ownerState.size === 'small' && {
          fontSize: theme.typography.body2.fontSize,
          minWidth: '150px',
          borderRadius: 12,
          padding: '8px 14px',
          fontWeight: '700',

          [theme.breakpoints.up('xxl')]: {
            borderRadius: 14
          }
        })
      }),

      ...(ownerState.variant === 'contained' && {
        boxShadow: theme.shadows[4],

        '&:hover': {
          opacity: 0.8
        },

        '&:disabled': {
          opacity: 0.4
        }
      }),

      ...(ownerState.variant === 'outlined' && {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        boxShadow: 'none',
        borderWidth: '2px',
        padding: '6px 16px',

        '&:hover': {
          borderWidth: '2px'
        },

        '&:disabled': {
          opacity: 0.4
        },

        '&:focus': {
          boxShadow: theme.shadows[4]
        }
      }),

      ...(ownerState.variant === 'text' && {
        textTransform: 'none',
        minWidth: 'fit-content',
        fontWeight: 500,

        ...(ownerState.size === 'medium' && {
          fontSize: theme.typography.body1.fontSize
        }),

        ...(ownerState.color === 'primary' && {
          color: theme.palette.text.primary
        }),

        ...(ownerState.color === 'secondary' && {
          color: theme.palette.text.secondary
        }),
        '&:disabled': {
          opacity: 0.4,
          color: 'inherit'
        },

        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.05)'
        },

        '&:focus': {
          backgroundColor: 'rgba(0, 0, 0, 0.05)'
        }
      })
    })
  }
};

export default ButtonOverride;
