import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { add, endOfDay, isValid, startOfDay, subDays } from 'date-fns';

import useOrderStatusType from 'Hooks/orders/useOrderStatusType';

import { FiltersValues, StoredFiltersValues } from './types';

export const useOrdersTableFilters = (filtersKey: string) => {
  const [filtersLoading, setFiltersLoading] = useState(true);

  const formMethods = useForm<FiltersValues>({
    mode: 'onChange'
  });
  const { reset, setValue, watch } = formMethods;
  const filters = watch();
  const { selectedStatusesIds } = filters;

  const { data: statuses, isLoading: isLoadingStatuses } = useOrderStatusType({
    enabled: !filtersLoading,
    onSuccess: (data) => {
      if (!selectedStatusesIds) {
        setValue(
          'selectedStatusesIds',
          data.map(({ id }) => id)
        );
      }
    }
  });

  useEffect(() => {
    const loadedFilters = localStorage.getItem(filtersKey);
    if (loadedFilters) {
      const parsedFilters = JSON.parse(loadedFilters);

      reset(mapStoredFiltersToFiltersValues(parsedFilters));
    } else {
      setValue('dateRange.from', startOfDay(subDays(new Date(), 7)));
      setValue('dateRange.to', endOfDay(add(new Date(), { days: 7 })));
      setValue('orderName', '');
    }
    setFiltersLoading(false);
  }, [filtersKey]);

  useEffect(() => {
    if (!filtersLoading) {
      localStorage.setItem(filtersKey, JSON.stringify(mapFiltersToPersistedStoreObject(filters)));
    }
  }, [filters, filtersLoading, filtersKey]);

  const value = useMemo(
    () => ({
      filtersLoading,
      setFiltersLoading,
      formMethods,
      filters,
      statuses,
      isLoadingStatuses
    }),
    [filtersLoading, formMethods, filters, statuses, isLoadingStatuses]
  );

  return value;
};

const mapStoredFiltersToFiltersValues = ({
  from,
  to,
  orderName,
  selectedStatusesIds
}: StoredFiltersValues): FiltersValues => {
  return {
    dateRange: {
      from: from && isValid(new Date(from)) ? new Date(from) : new Date(),
      to: to && isValid(new Date(to)) ? new Date(to) : new Date()
    },
    orderName: orderName ?? '',
    selectedStatusesIds: selectedStatusesIds ?? []
  };
};

const mapFiltersToPersistedStoreObject = ({
  dateRange,
  orderName,
  selectedStatusesIds
}: FiltersValues): StoredFiltersValues => {
  const from = dateRange?.from && isValid(dateRange.from) ? dateRange.from.toISOString() : null;
  const to = dateRange?.to && isValid(dateRange.to) ? dateRange.to.toISOString() : null;

  return {
    from,
    to,
    orderName,
    selectedStatusesIds
  };
};
