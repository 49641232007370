import { useTranslation } from 'react-i18next';
import { Dashboard as DashboardIcon, GridView } from '@mui/icons-material';

import paths from 'Routes/paths';

import PlanIcon from '../Icons/PlanIcon';
import NavLinks, { NavLinkItem } from '../NavLinks';

type Props = {
  collapsed: boolean;
};

const ForemanDashboardLinks = ({ collapsed }: Props) => {
  const { t } = useTranslation();

  const navItems: NavLinkItem[] = [
    {
      link: paths.foremanDashboard,
      Icon: PlanIcon,
      label: t('labels.production')
    },
    {
      link: paths.foremanMachinesDashboard,
      Icon: DashboardIcon,
      label: t('labels.machines')
    },
    {
      link: paths.foremanOrdersDashboard,
      Icon: GridView,
      label: t('labels.orders')
    }
  ];

  return <NavLinks items={navItems} collapsed={collapsed} />;
};

export default ForemanDashboardLinks;
