import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const PaperCardContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 400,
  maxWidth: 400,
  gap: theme.spacing(3),
  [theme.breakpoints.up('xxl')]: {
    width: 600,
    maxWidth: 600,
    gap: theme.spacing(6)
  }
}));

export default PaperCardContent;
