import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import AppNames from 'Consts/AppNames';

import paths from './paths';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const UserNotificationsPage = lazy(() => import('Pages/shared/NotificationsPage'));
const Dashboard = lazy(() => import('Pages/Operator/Dashboard'));
const RFIDLogin = lazy(() => import('Pages/Auth/RFIDLogin/RFIDLogin'));
const Login = lazy(() => import('Pages/Auth/Login'));
const ActivateAccount = lazy(() => import('Pages/Auth/ActivateAccount'));
const ForgotPassword = lazy(() => import('Pages/Auth/ForgotPassword'));
const MultiOperator = lazy(() => import('Pages/Auth/MultiOperator'));
const SignInCallback = lazy(() => import('Auth/SignInCallback'));
const MachineSelection = lazy(() => import('Pages/Operator/MachineSelection'));
const Order = lazy(() => import('Pages/Operator/OrderSelection'));
const ReportFailureReason = lazy(() => import('Pages/Operator/ReportFailureReason'));
const ReportIncompleteOrderReason = lazy(() => import('Pages/Operator/ReportIncompleteOrderReason'));
const ReportIncompleteVariantRetoolReason = lazy(() => import('Pages/Operator/ReportIncompleteVariantRetoolReason'));
const Report = lazy(() => import('Pages/Operator/Report'));
const EnforcedReport = lazy(() => import('Pages/Operator/EnforcedReport'));
const Files = lazy(() => import('Pages/Operator/Files'));
const Feed = lazy(() => import('Pages/Operator/Feed'));
const NotFound = lazy(() => import('Pages/shared/error-pages/NotFound'));
const ForemanDashboard = lazy(() => import('Pages/Foreman/Dashboard'));
const ForemanMachinesDashboard = lazy(() => import('Pages/Foreman/MachinesDashboard'));
const ForemanOrdersDashboard = lazy(() => import('Pages/Foreman/OrdersDashboard/OrdersDashboard'));
const ProductionFlow = lazy(() => import('Pages/Foreman/ProductionFlow'));
const ForemanIdleReasons = lazy(() => import('Pages/Foreman/IdleReasons'));
const ForemanFailureReasons = lazy(() => import('Pages/Foreman/FailureReasons'));
const ForemanWorkWithFaiilureReasons = lazy(() => import('Pages/Foreman/WorkWithFailureReasons'));
const ScrapsClassification = lazy(() => import('Pages/Foreman/ScrapsClassification'));
const ComponentScrapsClassification = lazy(() => import('Pages/Foreman/ComponentScrapsClassification'));
const ForemanLineStatus = lazy(() => import('Pages/Foreman/LineStatus'));
const ForemanKanban = lazy(() => import('Pages/Foreman/Kanban'));
const ForemanPDCA = lazy(() => import('Pages/Foreman/PDCA'));
const MaintenancePDCA = lazy(() => import('Pages/Maintenance/PDCA'));
const ForemanFiles = lazy(() => import('Pages/Foreman/Files'));
const ForemanProductivity = lazy(() => import('Pages/Foreman/Productivity'));
const ForemanYamazumi = lazy(() => import('Pages/Foreman/Yamazumi'));
const ForemanPerformacneDrops = lazy(() => import('Pages/Foreman/PerformanceDrops'));
const ForemanOeeChart = lazy(() => import('Pages/Foreman/OEE'));
const ForemanOrders = lazy(() => import('Pages/Foreman/Orders'));
const ForemanRetools = lazy(() => import('Pages/Foreman/Retools'));
const TechnologistWorkstations = lazy(() => import('Pages/Technologist/Workstations'));
const AdminDataLoggers = lazy(() => import('Pages/Admin/DataLoggers'));
const TechnologistLines = lazy(() => import('Pages/Technologist/Lines'));
const TechnologistVariants = lazy(() => import('Pages/Technologist/Variants'));
const technologistParameters = lazy(() => import('Pages/Technologist/Parameters'));
const AdminCatalogs = lazy(() => import('Pages/Admin/Catalogs'));
const AdminShiftEditor = lazy(() => import('Pages/Admin/ShiftEditor'));
const AdminNotificationManager = lazy(() => import('Pages/Admin/NotificationManager'));
const AdminUsers = lazy(() => import('Pages/Admin/Users'));
const AdminRoles = lazy(() => import('Pages/Admin/Roles'));
const AdminTranslations = lazy(() => import('Pages/Admin/Translations'));
const MachineRemoved = lazy(() => import('Pages/shared/error-pages/MachineRemoved'));
const MachineDeactivated = lazy(() => import('Pages/shared/error-pages/MachineDeactivated'));
const MachineAlreadyPaired = lazy(() => import('Pages/shared/error-pages/MachineAlreadyPaired'));
// const MaintenanceDashboard = lazy(() => import('Pages/Maintenance/Dashboard'));
const MaintenanceLineStatus = lazy(() => import('Pages/Maintenance/LineStatus'));
const MaintenanceFiles = lazy(() => import('Pages/Maintenance/Files'));
const MaintenanceCheckLists = lazy(() => import('Pages/Maintenance/CheckLists'));
const MaintenanceRepairTaskAssignment = lazy(() => import('Pages/Maintenance/RepairTaskAssignment'));
const MaintenanceMaintenanceTasksAssignment = lazy(() => import('Pages/Maintenance/MaintenanceTasksAssignment'));
const MaintenanceLineLayout = lazy(() => import('Pages/Maintenance/MaintenanceLineLayout'));
const MaintenanceMaintenancePlanner = lazy(() => import('Pages/Maintenance/MaintenancePlanner'));
const MechanicDashboard = lazy(() => import('Pages/Mechanic/Dashboard'));
const MechanicTaskDetails = lazy(() => import('Pages/Mechanic/FailurePreview'));
const TechnologistTechnologyEditor = lazy(() => import('Pages/Technologist/TechnologyEditor'));
const ReportUnsuccessfulRepairReason = lazy(
  () => import('Pages/Mechanic/FailurePreview/ReportUnsuccessfulTaskReason/ReportUnsuccessfulRepairTaskReason')
);
const ReportUnsuccessfulMaintenanceReason = lazy(
  () => import('Pages/Mechanic/FailurePreview/ReportUnsuccessfulTaskReason/ReportUnsuccessfulMaintenanceTaskReason')
);
const ReportSuccessfulReason = lazy(() => import('Pages/Mechanic/FailurePreview/ReportSuccessfulReason'));
const ReportStopReason = lazy(() => import('Pages/Mechanic/FailurePreview/ReportStopReason'));
const ReportFailure = lazy(() => import('Pages/Operator/ReportFailure'));
const ReportScrapReason = lazy(() => import('Pages/Operator/ReportScrapReason'));
const ReportManualScrapReason = lazy(() => import('Pages/Operator/ReportManualScrapReason'));
const OperatorClassifyInvalidBunch = lazy(() => import('Pages/Operator/ClassifyInvalidBunch'));
const CallForeman = lazy(() => import('Pages/Operator/CallForeman'));
const ActivationLinkExpired = lazy(() => import('Pages/Auth/ErrorPage/ActivationLinkExpired'));
const ActivationLinkIncorrect = lazy(() => import('Pages/Auth/ErrorPage/ActivationLinkIncorrect'));
const ResetPassword = lazy(() => import('Pages/Auth/ResetPassword'));
const ResetPasswordByOldPassword = lazy(() => import('Pages/Auth/ResetPasswordByOldPassword'));
const ResetPasswordLinkExpired = lazy(() => import('Pages/Auth/ErrorPage/ResetPasswordLinkExpired'));
const ResetPasswordLinkIncorrect = lazy(() => import('Pages/Auth/ErrorPage/ResetPasswordLinkIncorrect'));
const Unauthorized = lazy(() => import('Pages/shared/error-pages/Unauthorized'));
const Forbidden = lazy(() => import('Pages/shared/error-pages/Forbidden'));
const Home = lazy(() => import('Pages/shared/HomePage'));
const AppSelection = lazy(() => import('Pages/shared/AppSelectionPage'));
const Settings = lazy(() => import('Pages/Operator/MachineSelection'));
const LanguageSelection = lazy(() => import('Pages/shared/LanguageSelectionPage'));
const OperatorProductionPlan = lazy(() => import('Pages/Operator/ProductionPlan'));
const AccessDenied = lazy(() => import('Pages/shared/error-pages/AccessDenied'));
const PairedPanels = lazy(() => import('Pages/Admin/PairedPanels'));
const WorkstationDisconnected = lazy(() => import('Pages/shared/error-pages/WorkstationDisconnected'));
const Output = lazy(() => import('Pages/Foreman/Output'));
const PlannerOrders = lazy(() => import('Pages/Planner/Orders'));
const OperatorPairWithMachine = lazy(() => import('Pages/Operator/PairWithMachine/PairWithMachine'));
const OperatorWorkstationHistory = lazy(() => import('Pages/Operator/WorkstationHistory'));
const MechanicFailureAssignment = lazy(() => import('Pages/Mechanic/FailureAssignment'));
const ForemanStatusesTimelineChart = lazy(() => import('Pages/Foreman/StatusesTimeline'));
const OperatorReportComponentScrapReason = lazy(() => import('Pages/Operator/ReportComponentScrapReason'));
const OperatorReportSuspendedRetool = lazy(() => import('Pages/Operator/ReportSuspendedRetool'));
const OperatorReportRework = lazy(() => import('Pages/Operator/ReportRework'));
const OperatorLandingPage = lazy(() => import('Pages/Operator/LandingPage'));
const OperatorFinishOrderPage = lazy(() => import('Pages/Operator/FinishOrder'));
const OperatorClassifyPotentialFailureAsFailure = lazy(
  () => import('Pages/Operator/ClassifyPotentialFailureAsFailure')
);
const OperatorClassifyPotentialFailureAsFailureReason = lazy(
  () => import('Pages/Operator/ClassifyPotentialFailureAsFailureReason')
);
const OperatorClassifyPotentialFailureAsIdleReason = lazy(
  () => import('Pages/Operator/ClassifyPotentialFailureAsIdleReason')
);
const ReworksPage = lazy(() => import('Pages/Foreman/Reworks/ReworksPage'));

const Routes = () => (
  <Switch>
    <PublicRoute route={{ component: Login, name: 'Login' }} path={paths.login} exact />
    <PublicRoute route={{ component: RFIDLogin, name: 'RFID Login' }} path={paths.rfidLogin} exact />
    <PublicRoute route={{ component: ActivateAccount, name: 'Activate Account' }} path={paths.activateAccount} exact />
    <PublicRoute
      route={{ component: ActivationLinkExpired, name: 'Activation Link Expired' }}
      path={paths.activationLinkExpired}
      exact
    />
    <PublicRoute
      route={{ component: ActivationLinkIncorrect, name: 'Activation Link Incorrect' }}
      path={paths.activationLinkIncorrect}
      exact
    />
    <PublicRoute
      route={{ component: ResetPasswordLinkExpired, name: 'Reset Password Link Expired' }}
      path={paths.passwordResetLinkExpired}
      exact
    />
    <PublicRoute
      route={{ component: ResetPasswordLinkIncorrect, name: 'Reset Password Link Incorrect' }}
      path={paths.passwordResetLinkIncorrect}
      exact
    />
    <PublicRoute route={{ component: ForgotPassword, name: 'Forgot Password' }} path={paths.forgotPassword} exact />
    <PublicRoute route={{ component: ResetPassword, name: 'Reset Password' }} path={paths.resetPassword} exact />
    <PublicRoute
      route={{ component: ResetPasswordByOldPassword, name: 'Reset Password by old password' }}
      path={paths.resetPasswordByOldPassword}
      exact
    />
    <PublicRoute route={{ component: SignInCallback, name: 'Sign In Callback' }} path={paths.signinCallback} exact />
    <PublicRoute route={{ component: NotFound, name: 'Not Found' }} path={paths.notFound} exact />
    <PublicRoute route={{ component: Unauthorized, name: 'Unauthorized' }} path={paths.unauthorized} exact />
    <PublicRoute route={{ component: Forbidden, name: 'Forbidden' }} path={paths.forbidden} exact />
    <PrivateRoute route={{ component: Home, name: 'Home' }} path={paths.installedApp} exact />
    <PrivateRoute route={{ component: Home, name: 'Home' }} path={paths.root} exact />
    <PrivateRoute route={{ component: Home, name: 'Home' }} path={paths.home} exact />
    <PrivateRoute route={{ component: AppSelection, name: 'App Selection' }} path={paths.appSelection} exact />
    <PrivateRoute
      route={{ component: OperatorPairWithMachine, name: 'Paired Machine Availability Check' }}
      path={paths.operatorPairWithMachine}
      exact
    />
    <PublicRoute
      route={{ component: LanguageSelection, name: 'Language Selection' }}
      path={paths.languageSelection}
      exact
    />
    <PublicRoute route={{ component: MachineRemoved, name: 'Machine Removed' }} path={paths.machineRemoved} exact />
    <PublicRoute
      route={{ component: MachineDeactivated, name: 'Machine Deactivated' }}
      path={paths.machineDeactivated}
      exact
    />
    <PublicRoute route={{ component: AccessDenied, name: 'Access Denied' }} path={paths.accessDenied} exact />
    <PublicRoute
      route={{ component: MachineAlreadyPaired, name: 'Machine Already Paired' }}
      path={paths.machineAlreadyPaired}
      exact
    />
    <PublicRoute
      route={{ component: WorkstationDisconnected, name: 'Workstation Disconnected' }}
      path={paths.workstationDisconnected}
      exact
    />
    {
      // #region OperatorPanel
    }
    <PublicRoute
      route={{ component: MachineSelection, name: 'Machine Selection', app: AppNames.OperatorPanel }}
      path={paths.setMachine}
      exact
    />
    <PrivateRoute
      route={{ component: OperatorLandingPage, name: 'Operator Landing Page', app: AppNames.OperatorPanel }}
      path={paths.operatorLandingPage}
      exact
    />
    <PrivateRoute
      route={{ component: ReportFailure, app: AppNames.OperatorPanel, name: 'Failure Check List' }}
      path={paths.operatorFailureCheckList}
      exact
    />
    <PrivateRoute
      route={{ component: Order, app: AppNames.OperatorPanel, name: 'Order' }}
      path={paths.operatorRetool}
      exact
    />
    <PrivateRoute
      route={{ component: Report, app: AppNames.OperatorPanel, name: 'Report Idle' }}
      path={paths.operatorReportIdleReason}
      exact
    />
    <PrivateRoute
      route={{ component: EnforcedReport, app: AppNames.OperatorPanel, name: 'Enforced Report' }}
      path={paths.operatorEnforcedReport}
      exact
    />
    <PrivateRoute
      route={{ component: ReportFailureReason, app: AppNames.OperatorPanel, name: 'Report Failure' }}
      path={paths.operatorReportFailureReason}
      exact
    />
    <PrivateRoute
      route={{ component: ReportIncompleteOrderReason, app: AppNames.OperatorPanel, name: 'Report Incomplete Order' }}
      path={paths.operatorReportIncompleteOrderReason}
      exact
    />
    <PrivateRoute
      route={{
        component: ReportIncompleteVariantRetoolReason,
        app: AppNames.OperatorPanel,
        name: 'Report Incomplete Variant Retool'
      }}
      path={paths.operatorReportIncompleteVariantRetoolReason}
      exact
    />
    <PrivateRoute
      route={{ component: Feed, app: AppNames.OperatorPanel, name: 'Notifications' }}
      path={paths.operatorNotifications}
      exact
    />
    <PrivateRoute
      route={{ component: Dashboard, app: AppNames.OperatorPanel, name: 'Dashboard' }}
      path={paths.operatorDashboard}
      exact
    />
    <PrivateRoute
      route={{ component: Files, app: AppNames.OperatorPanel, name: 'Files' }}
      path={paths.operatorFiles}
      exact
    />
    <PrivateRoute
      route={{ component: ReportScrapReason, app: AppNames.OperatorPanel, name: 'Report Scrap' }}
      path={paths.operatorReportScrapReason}
      exact
    />
    <PrivateRoute
      route={{ component: ReportManualScrapReason, app: AppNames.OperatorPanel, name: 'Report Scrap' }}
      path={paths.operatorReportManualScrapReason}
      exact
    />
    <PrivateRoute
      route={{ component: OperatorClassifyInvalidBunch, app: AppNames.OperatorPanel, name: 'Report Scrap' }}
      path={paths.operatorClassifyInvalidBunch}
      exact
    />
    <PrivateRoute
      route={{ component: CallForeman, app: AppNames.OperatorPanel, name: 'Call Foreman' }}
      path={paths.operatorCallForeman}
      exact
    />
    <PrivateRoute
      route={{ component: Settings, app: AppNames.OperatorPanel, name: 'Settings' }}
      path={paths.operatorSettings}
      exact
    />
    <PrivateRoute
      route={{ component: OperatorProductionPlan, app: AppNames.OperatorPanel, name: 'Production Flow' }}
      path={paths.operatorProductionFlow}
      exact
    />
    <PrivateRoute
      route={{ component: OperatorWorkstationHistory, app: AppNames.OperatorPanel, name: 'Workstation History' }}
      path={paths.operatorWorkstationHistory}
      exact
    />
    <PrivateRoute
      route={{ component: OperatorFinishOrderPage, app: AppNames.OperatorPanel, name: 'Finish order' }}
      path={paths.operatorFinishOrder}
      exact
    />
    <PrivateRoute
      route={{
        component: OperatorReportComponentScrapReason,
        app: AppNames.OperatorPanel,
        name: 'Report Component Scrap'
      }}
      path={paths.operatorReportComponentScrapReason}
      exact
    />
    <PrivateRoute
      route={{
        component: OperatorReportSuspendedRetool,
        app: AppNames.OperatorPanel,
        name: 'Report Suspended Retool'
      }}
      path={paths.operatorReportSuspendedRetoolReason}
      exact
    />
    <PrivateRoute
      route={{
        component: OperatorReportRework,
        app: AppNames.OperatorPanel,
        name: 'Report Rework'
      }}
      path={paths.operatorReportReworkReason}
      exact
    />
    <PrivateRoute
      route={{
        component: MultiOperator,
        app: AppNames.OperatorPanel,
        name: 'Multi-operator'
      }}
      path={paths.operatorMultiOperator}
    />
    <PrivateRoute
      route={{
        component: OperatorClassifyPotentialFailureAsFailure,
        app: AppNames.OperatorPanel,
        name: 'Failure Check List'
      }}
      path={paths.operatorClassifyPotentialFailureAsFailure}
    />
    <PrivateRoute
      route={{
        component: OperatorClassifyPotentialFailureAsFailureReason,
        app: AppNames.OperatorPanel,
        name: 'Classify Potential Failure As Failure'
      }}
      path={paths.operatorClassifyPotentialFailureAsFailureReason}
    />
    <PrivateRoute
      route={{
        component: OperatorClassifyPotentialFailureAsIdleReason,
        app: AppNames.OperatorPanel,
        name: 'Classify Potential Failure As Idle'
      }}
      path={paths.operatorClassifyPotentialFailureAsIdleReason}
    />
    {
      // #endregion
    }
    {
      // #region AdminPanel
    }
    <PrivateRoute
      route={{ component: AdminCatalogs, app: AppNames.AdminPanel, name: 'Catalogs' }}
      path={paths.adminCatalogs}
      exact
    />
    <PrivateRoute
      route={{ component: AdminShiftEditor, app: AppNames.AdminPanel, name: 'Shift Editor' }}
      path={paths.adminShiftEditor}
      exact
    />
    <PrivateRoute
      route={{
        component: AdminNotificationManager,
        app: AppNames.AdminPanel,
        name: 'Notification Manager'
      }}
      path={paths.adminNotificationManager}
      exact
    />
    <PrivateRoute
      route={{ component: AdminUsers, app: AppNames.AdminPanel, name: 'Users' }}
      path={paths.adminUsers}
      exact
    />
    <PrivateRoute
      route={{ component: AdminRoles, app: AppNames.AdminPanel, name: 'Roles' }}
      path={paths.adminRoles}
      exact
    />
    <PrivateRoute
      route={{ component: UserNotificationsPage, app: AppNames.AdminPanel, name: 'Notifications' }}
      path={paths.adminNotifications}
      exact
    />
    <PrivateRoute
      route={{ component: AdminTranslations, app: AppNames.AdminPanel, name: 'Translations' }}
      path={paths.adminTranslations}
      exact
    />
    <PrivateRoute
      route={{ component: PairedPanels, app: AppNames.AdminPanel, name: 'Paired Panels' }}
      path={paths.adminPairedPanels}
      exact
    />
    <PrivateRoute
      route={{ component: AdminDataLoggers, app: AppNames.AdminPanel, name: 'Data Loggers' }}
      path={paths.adminDataLoggers}
      exact
    />
    {
      // #endregion
    }
    {
      // #region TechnologistPanel
    }
    <PrivateRoute
      route={{ component: TechnologistWorkstations, app: AppNames.TechnologistPanel, name: 'Workstations' }}
      path={paths.technologistWorkstations}
      exact
    />
    <PrivateRoute
      route={{ component: TechnologistLines, app: AppNames.TechnologistPanel, name: 'Lines' }}
      path={paths.technologistLines}
      exact
    />
    <PrivateRoute
      route={{ component: TechnologistVariants, app: AppNames.TechnologistPanel, name: 'Variants' }}
      path={paths.technologistVariants}
      exact
    />
    <PrivateRoute
      route={{
        component: UserNotificationsPage,
        app: AppNames.TechnologistPanel,
        name: 'Notifications'
      }}
      path={paths.technologistNotifications}
      exact
    />
    <PrivateRoute
      route={{
        component: technologistParameters,
        app: AppNames.TechnologistPanel,
        name: 'Parameters'
      }}
      path={paths.technologistParameters}
      exact
    />
    <PrivateRoute
      route={{
        component: TechnologistTechnologyEditor,
        app: AppNames.TechnologistPanel,
        name: 'Technology Editor'
      }}
      path={paths.technologistTechnologyEditor}
      exact
    />
    {
      // #endregion
    }
    <PrivateRoute
      route={{ component: ForemanDashboard, app: AppNames.ForemanDashboard, name: 'Dashboard' }}
      path={paths.foremanDashboard}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanMachinesDashboard, app: AppNames.ForemanDashboard, name: 'Machines Dashboard' }}
      path={paths.foremanMachinesDashboard}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanOrdersDashboard, app: AppNames.ForemanDashboard, name: 'Orders Dashboard' }}
      path={paths.foremanOrdersDashboard}
      exact
    />
    {
      // #region ForemanPanel
    }
    <PrivateRoute
      route={{ component: UserNotificationsPage, app: AppNames.ForemanPanel, name: 'Notifications' }}
      path={paths.foremanNotifications}
      exact
    />
    <PrivateRoute
      route={{ component: ProductionFlow, app: AppNames.ForemanPanel, name: 'Production Flow' }}
      path={paths.foremanProductionFlow}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanIdleReasons, app: AppNames.ForemanPanel, name: 'Idle Reasons' }}
      path={paths.foremanIdleReasons}
      exact
    />
    <PrivateRoute
      route={{
        component: ForemanFailureReasons,
        app: AppNames.ForemanPanel,
        name: 'Failure Reasons'
      }}
      path={paths.foremanFailureReasons}
      exact
    />
    <PrivateRoute
      route={{
        component: ForemanWorkWithFaiilureReasons,
        app: AppNames.ForemanPanel,
        name: 'Work with Failure Reasons'
      }}
      path={paths.foremanWorkWithFaiilureReasons}
      exact
    />
    <PrivateRoute
      route={{ component: ScrapsClassification, app: AppNames.ForemanPanel, name: 'Scraps Classification' }}
      path={paths.foremanScrapsClassification}
      exact
    />
    <PrivateRoute
      route={{
        component: ComponentScrapsClassification,
        app: AppNames.ForemanPanel,
        name: 'Component Scraps Classification'
      }}
      path={paths.foremanComponentScrapsClassification}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanLineStatus, app: AppNames.ForemanPanel, name: 'Line Status' }}
      path={paths.foremanLineStatus}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanKanban, app: AppNames.ForemanPanel, name: 'Kanban' }}
      path={paths.foremanKanban}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanPDCA, app: AppNames.ForemanPanel, name: 'PDCA' }}
      path={paths.foremanPDCA}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanFiles, app: AppNames.ForemanPanel, name: 'Files' }}
      path={paths.foremanFiles}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanOrders, app: AppNames.ForemanPanel, name: 'Orders' }}
      path={paths.foremanOrders}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanProductivity, app: AppNames.ForemanPanel, name: 'Productivity' }}
      path={paths.foremanProductivity}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanYamazumi, app: AppNames.ForemanPanel, name: 'Yamazumi' }}
      path={paths.foremanYamazumi}
      exact
    />
    <PrivateRoute
      route={{ component: Output, app: AppNames.ForemanPanel, name: 'Output' }}
      path={paths.foremanOutput}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanPerformacneDrops, app: AppNames.ForemanPanel, name: 'Performance Drops' }}
      path={paths.foremanPerformanceDrops}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanStatusesTimelineChart, app: AppNames.ForemanPanel, name: 'Statuses Timeline' }}
      path={paths.foremanStatusesTimelineChart}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanOeeChart, app: AppNames.ForemanPanel, name: 'OEE Chart' }}
      path={paths.foremanOeeChart}
      exact
    />
    <PrivateRoute
      route={{ component: ForemanRetools, app: AppNames.ForemanPanel, name: 'Retools' }}
      path={paths.foremanRetools}
      exact
    />
    {
      // #endregion
    }
    {/*<PrivateRoute*/}
    {/*  route={{ component: MaintenanceDashboard, app: AppNames.MaintenanceDashboard, name: 'Dashboard' }}*/}
    {/*  path={paths.maintenanceDashboard}*/}
    {/*  exact*/}
    {/*/>*/}
    <PrivateRoute
      route={{ component: MaintenanceLineStatus, app: AppNames.MaintenanceDashboard, name: 'Line Status' }}
      path={paths.maintenanceLineStatus}
      exact
    />
    <PrivateRoute
      route={{ component: MaintenanceFiles, app: AppNames.MaintenanceDashboard, name: 'Files' }}
      path={paths.maintenanceFiles}
      exact
    />
    <PrivateRoute
      route={{ component: MaintenanceCheckLists, app: AppNames.MaintenanceDashboard, name: 'Check Lists' }}
      path={paths.maintenanceCheckLists}
      exact
    />
    <PrivateRoute
      route={{
        component: MaintenanceRepairTaskAssignment,
        app: AppNames.MaintenanceDashboard,
        name: 'Repair Tasks Assignment'
      }}
      path={paths.maintenanceRepairTasksAssignment}
      exact
    />
    <PrivateRoute
      route={{
        component: MaintenanceMaintenanceTasksAssignment,
        app: AppNames.MaintenanceDashboard,
        name: 'Maintenance Tasks Assignment'
      }}
      path={paths.maintenanceMaintenanceTasksAssignment}
      exact
    />
    <PrivateRoute
      route={{ component: MaintenanceLineLayout, app: AppNames.MaintenanceDashboard, name: 'Line Layout' }}
      path={paths.maintenanceLineLayout}
      exact
    />
    <PrivateRoute
      route={{ component: UserNotificationsPage, app: AppNames.MaintenanceDashboard, name: 'Notifications' }}
      path={paths.maintenanceNotifications}
      exact
    />
    <PrivateRoute
      route={{ component: MaintenancePDCA, app: AppNames.MaintenanceDashboard, name: 'PDCA' }}
      path={paths.maintenancePdca}
      exact
    />
    <PrivateRoute
      route={{
        component: MaintenanceMaintenancePlanner,
        app: AppNames.MaintenanceDashboard,
        name: 'Maintenance Planner'
      }}
      path={paths.maintenanceMaintenancePlanner}
      exact
    />
    <PrivateRoute
      route={{ component: ReworksPage, app: AppNames.ForemanPanel, name: 'Reworks' }}
      path={paths.foremanReworks}
      exact
    />
    {
      // #region MechanicPanel
    }
    <PrivateRoute
      route={{ component: MechanicDashboard, app: AppNames.MechanicPanel, name: 'Dashboard' }}
      path={paths.mechanicDashboard}
      exact
    />
    <PrivateRoute
      route={{ component: MechanicFailureAssignment, app: AppNames.MechanicPanel, name: 'Failure Assignment' }}
      path={paths.mechanicFailureAssignment}
      exact
    />
    <PrivateRoute
      route={{ component: MechanicTaskDetails, app: AppNames.MechanicPanel, name: 'Mechanic Task Details' }}
      path={`${paths.mechanicTaskDetails}/:id`}
      exact
    />
    <PrivateRoute
      route={{
        component: ReportUnsuccessfulRepairReason,
        app: AppNames.MechanicPanel,
        name: 'Report Unsuccessful Repair Reason'
      }}
      path={`${paths.mechanicTaskDetails}/:id${paths.reportUnsuccessfulRepairReason}`}
      exact
    />
    <PrivateRoute
      route={{
        component: ReportUnsuccessfulMaintenanceReason,
        app: AppNames.MechanicPanel,
        name: 'Report Unsuccessful Maintenance Reason'
      }}
      path={`${paths.mechanicTaskDetails}/:id${paths.reportUnsuccessfulMaintenanceReason}`}
      exact
    />
    <PrivateRoute
      route={{
        component: ReportStopReason,
        app: AppNames.MechanicPanel,
        name: 'Report Stop Reason'
      }}
      path={`${paths.mechanicTaskDetails}/:id${paths.reportStopReason}`}
      exact
    />
    <PrivateRoute
      route={{ component: ReportSuccessfulReason, app: AppNames.MechanicPanel, name: 'Report Successful Reason' }}
      path={`${paths.mechanicTaskDetails}/:id${paths.reportSuccessfulReason}`}
      exact
    />
    {
      // #endregion
    }
    {
      // #region PlannerPanel
    }
    <PrivateRoute
      route={{ component: UserNotificationsPage, app: AppNames.PlannerPanel, name: 'Notifications' }}
      path={paths.plannerNotifications}
      exact
    />
    <PrivateRoute
      route={{ component: PlannerOrders, app: AppNames.PlannerPanel, name: 'Orders' }}
      path={paths.plannerOrders}
      exact
    />
    {
      // #endregion
    }
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
