import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';

interface DXNumberInputProps {
  name: string;
  label: string;
  style?: React.CSSProperties;
}

export const NumberInput: FC<DXNumberInputProps> = (props) => {
  const { control } = useFormContext();
  const {
    field: { ref, value, ...fieldProps },
    fieldState
  } = useController({ name: props.name, control });

  const handleInputValueChange = (value: string) => {
    const result = !value ? '' : Number(value);
    fieldProps.onChange(result);
  };

  return (
    <TextField
      inputRef={ref}
      label={props.label}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      value={value}
      fullWidth
      type='number'
      onChange={(e) => handleInputValueChange(e.target.value)}
      style={props.style}
    />
  );
};
